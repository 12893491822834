import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import C1 from "../../assets/images/sp-1.webp";
import C2 from "../../assets/images/sp-2.webp";
import C3 from "../../assets/images/sp-3.webp";
import C4 from "../../assets/images/sp-4.webp";
import C5 from "../../assets/images/sp-5.webp";
import C6 from "../../assets/images/sp-6.webp";
import C7 from "../../assets/images/sp-7.webp";
import C8 from "../../assets/images/sp-8.webp";
import C9 from "../../assets/images/sp-9.webp";
import C10 from "../../assets/images/sp-10.webp";
import C11 from "../../assets/images/sp-11.webp";
import C12 from "../../assets/images/sp-12.webp";
import C13 from "../../assets/images/sp-13.webp";
import C14 from "../../assets/images/sp-14.webp";
import C15 from "../../assets/images/sp-15.webp";
import C16 from "../../assets/images/sp-16.webp";
import C17 from "../../assets/images/sp-17.webp";
import C18 from "../../assets/images/sp-18.webp";
import C19 from "../../assets/images/sp-19.webp";

export const OurClients = () => {
  return (
    <div className="bg-gradient-container">
      <div className="container-fluid scroll-rotate-overflow-carousel">
        <div className="our-clients-container" style={{ padding: "5px 0px" }}>
          <OwlCarousel
            className="owl-theme"
            loop
            autoplay={true}
            items="1"
            dots={false}
            smartSpeed={2000}
            nav={false}
            margin={20}
            center={true}
            autoplayTimeout={2000}
            responsive={{
              360: {
                items: "2",
              },
              414: {
                items: "2",
              },
              670: {
                items: "3",
              },
              992: {
                items: "3",
              },
              1200: {
                items: "6",
              },
            }}
          >
            <div>
              <img className="client-img" src={C1} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C2} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C3} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C4} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C5} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C6} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C7} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C8} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C9} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C10} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C11} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C12} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C13} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C14} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C15} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C16} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C17} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C18} alt=""></img>
            </div>
            <div>
              <img className="client-img" src={C19} alt=""></img>
            </div>
          </OwlCarousel>
        </div>
        <div className="our-clients-container-bg"></div>
      </div>
    </div>
  );
};
