import React from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import PartnerWithUs from "./Pages/PartnerWithUs";
import Agenda from "./Pages/Agenda";
import BuyTicket from "./Pages/BuyTicket";
import Awards from "./Pages/Awards";
import NominationForm from "./Components/Awards/NominationForm";
import ThankYouPage from "./Pages/ThankYouPage";
import NominationAgencyAward from "./Components/Awards/NominationAgencyAward";

export const Main = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="PartnerWithUs" element={<PartnerWithUs />} />
        <Route path="Agenda" element={<Agenda />} />
        <Route path="BuyTicket" element={<BuyTicket />} />
        <Route path="Awards" element={<Awards />} />
        <Route path="InfluencerAwardNominationForm" element={<NominationForm />} />
        <Route path="AgencyAwardNominationForm" element={<NominationAgencyAward />} />
        <Route path="ThankYouPage" element={<ThankYouPage />} />
      </Routes>
    </HashRouter>
  );
};
