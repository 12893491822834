import React, { useState, useEffect } from "react";
import axios from "axios";
import ThankYouModal from "../Modal/ThankyouModal";
const PartnerWithUsContent = () => {


    const [value, setValue] = useState({});
    const [validation, setValidation] = useState({});
    const [thankYouModal, setThankYouModal] = useState(false);
    const [loading, setLoading] = useState(false);

    function isEmail(email) {
        return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    }


    const CheckValidation = () => {

        let Status = true,
            validdateData = {};
        if (!value.name || !value.name?.length) {
            validdateData.name = "Name is Required";
            Status = false;
        } else if (!isNaN(value.name)) {
            validdateData.name = "Name is invalid";
            Status = false;
        } else if (value.name === undefined) {
            validdateData.name = "Name is Required";
            Status = false;
        } else if (value.name === "") {
            validdateData.name = "Name is Required";
            Status = false;
        } else if (value.name.length <= 1) {
            validdateData.name = "Name must be maximum 2 letters";
            Status = false;
        }

        if (!value.mobileNumber === undefined) {
            validdateData.mobileNumber = "Mobile Number is Required";
            Status = false;
        } else if (!value.mobileNumber?.toString().length) {
            validdateData.mobileNumber = "Mobile Number is Required";
            Status = false;
        } else if (value.mobileNumber.toString().length !== 10) {
            validdateData.mobileNumber = "Mobile Number must be 10 digits";
            Status = false;
        }

        // if (!value.Email) {
        //     validdateData.Email = "Email is Required";
        //     Status = false;
        //   }
        if (!value.email?.trim()?.length) {
            validdateData.email = "Email is Required";
            Status = false;
        }
        else if (value.email?.includes(" ")) {
            validdateData.email = "Email cannot include spaces";
            Status = false;
        }
        else if (value.email && isEmail(value.email)) {
            validdateData.email = "Email is Invalid";
            Status = false;
        }

        if (!value.companyName) {
            validdateData.companyName = "Company Name is Required";
            Status = false;
        }
        if (!value.companyWebsite) {
            validdateData.companyWebsite = "Company Website is Required";
            Status = false;
        }
        if (!value.positionInCompany) {
            validdateData.positionInCompany = "Position In Company is Required";
            Status = false;
        }
        setValidation({ ...validation, ...validdateData });
        return Status;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // console.log(validation);
        let inputvalue = {
            Name: value.name,
            MobileNumber: value.mobileNumber,
            Email: value.email,
            CompanyName: value.companyName,
            CompanyWebsite: value.companyWebsite,
            PositionInCompany: value.positionInCompany,
            Date: new Date(),
            Location : window.location.href,
        };
        let formdata = new FormData();
        for (let key in inputvalue) {
            formdata.append(key, inputvalue[key]);
        }
        if (CheckValidation()) {
            try {
                await axios
                    .post(
                        // "https://script.google.com/macros/s/AKfycbweMI_NzCH8ZzZ5kglFQncTvQKze_UmWCKAlTkkHc1eYte4woQQqYlN5POheNi0Wicx/exec",
                        "https://script.google.com/macros/s/AKfycbzyH-CEOXHrDwP0GmQV1kqlvx_OHpihCNV8hYYb7Q8OI5EWzDDJdxBCWP3TgBOy0Lg/exec",
                        formdata
                    )
                    .then(() => {
                        setValue({});
                        setThankYouModal(true);
                        e.target.reset();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                    });
            }
            catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        }
        else {
            setLoading(false);
        }
    };

    const setData = (e, key) => {
        setValue({ ...value, [key]: e });
        if (validation[key]) setValidation({ ...validation, [key]: false });
    };

    return (
        <div>
            <ThankYouModal
                open={thankYouModal}
                setOpen={() => {
                    setThankYouModal(false);
                }}
            />
            <div className="partnerwithus-banner">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="home-banner-content text-center">
                                <h1 className="banner-white-text fs-partner mb-0">BECOME</h1>
                                <h1 className="mb-0">
                                    <span className="banner-white-text fs-partner mb-0">A{' '}</span>
                                    <span className="textAnimationContent fs-partner mb-0">PARTNER</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="partnerwithuscontent pt-5">
                <div className="partnerwithus-pos">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-11">
                                <form className="" onSubmit={(e) => handleSubmit(e)}>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="mb-contact-form">
                                                <input type="text" className="form-control pws-form"
                                                    placeholder="Name*" name="name"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "name");
                                                    }} />
                                                {validation.name && (
                                                    <span className="validationmsg small">{validation.name}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-contact-form">
                                                <input type="number" className="form-control pws-form"
                                                    name="mobileNumber"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "mobileNumber");
                                                    }}
                                                    placeholder="Mobile Number*" />
                                                {validation.mobileNumber && (
                                                    <span className="validationmsg small">{validation.mobileNumber}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-contact-form">
                                                <input type="text" className="form-control pws-form"
                                                    name="email"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "email");
                                                    }}
                                                    placeholder="Email*" />
                                                {validation.email && (
                                                    <span className="validationmsg small">{validation.email}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-contact-form">
                                                <input type="text" className="form-control pws-form"
                                                    name="companyName"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "companyName");
                                                    }}
                                                    placeholder="Company Name*" />
                                                {validation.companyName && (
                                                    <span className="validationmsg small">{validation.companyName}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-contact-form">
                                                <input type="text" className="form-control pws-form"
                                                    name="companyWebsite"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "companyWebsite");
                                                    }}
                                                    placeholder="Company Website*" />
                                                {validation.companyWebsite && (
                                                    <span className="validationmsg small">{validation.companyWebsite}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-contact-form">
                                                <input type="text" className="form-control pws-form"
                                                    name="positionInCompany*"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "positionInCompany");
                                                    }}
                                                    placeholder="Position in Company" />
                                                {validation.positionInCompany && (
                                                    <span className="validationmsg small">{validation.positionInCompany}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mb-5">
                                            <div className="bg-dark btn-contact">
                                                <button className={`btn btn-anim-contact w-100 py-3  ${loading ? 'sending' : ''}`} type="submit" >
                                                    {loading ? (
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only ">Loading...</span>
                                                        </div>
                                                    ) : (
                                                        "Submit"
                                                    )}
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PartnerWithUsContent;
