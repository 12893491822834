import React from "react";
import TimerSection from "../TimerSection";
import SilverStar from "../../assets/images/silver-star.webp";
import GoldStar from "../../assets/images/gold-star.webp";
import DiamondStar from "../../assets/images/diamond-star.webp";
import TickRight from "../../assets/images/tick-right.webp";
import TickWrong from "../../assets/images/tick-wrong.webp";
import SoldOut from "../../assets/images/sold_out.webp";
import TicketBlack from "../../assets/images/ticket-black.webp";
import TicketWhite from "../../assets/images/ticket-white.webp";
import TicketGold from "../../assets/images/ticket-gold.webp";
import { Link } from "react-router-dom";
const BuyTicketContent = () => {
  return (
    <div className="buy-ticket-page">
      <div className="mt-5">
        <div className="">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="stroke-text-container d-flex justify-content-center">
                  <h1 className="bg-text-stroke">BOOKINGS</h1>
                  <h1 className="gradient-text-heading ">Reserve your SEATS</h1>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="position-soldout">
                      <div className="silver-section">
                        <div className="text-center">
                          <img className="silver-star" src={SilverStar} alt="" />
                          <h2 className="font-bold text-dark">Silver</h2>
                        </div>
                        <div className="bg-dark text-center py-3">
                          <h1 className="banner-white-text ">9,999</h1>
                          <p className="mb-0 banner-para-content font-bold ">
                            + GST
                          </p>
                        </div>
                        <div className="silver-ticket-padding">
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Conference Kit</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Lunch day - 1</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Dinner day - 1</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Lunch day - 2</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Award night</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickWrong}
                              alt=""
                            />
                            <p className="mb-0">
                              Video replay access of the event for 1 year
                            </p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickWrong}
                              alt=""
                            />
                            <p className="mb-0">21 Days challenge June 2024</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickWrong}
                              alt=""
                            />
                            <p className="mb-0">
                              1 Hour Private Session with Vishnu Hari
                            </p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickWrong}
                              alt=""
                            />
                            <p className="mb-0">
                              Photo Opportunity with influencers & vip
                            </p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickWrong}
                              alt=""
                            />
                            <p className="mb-0">
                              Lunch & Dinner with influencers & vip
                            </p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickWrong}
                              alt=""
                            />
                            <p className="mb-0">Red carpet welcome</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickWrong}
                              alt=""
                            />
                            <p className="">
                              1 Hour consulting with Dharaneetharan
                            </p>
                          </div>
                          <div>
                            <h6 className="mb-4">
                              Seating arrangement -{" "}
                              <span className="font-bold">NORMAL</span>
                            </h6>
                          </div>
                          {/* <Link
                          tnds
                           to="https://pages.razorpay.com/pl_NuZ5GVXi3J9zLp/view"
                          seyal
                          to="https://pages.razorpay.com/pl_O2SutUDqI4SJGB/view"
                          
                        >
                          <button
                            className="btn black-btn-trans w-100"
                            data="Buy Ticket"
                          >
                            <div className="figure">
                              <img
                                className="ticket-black"
                                src={TicketBlack}
                                alt=""
                              />
                              <img
                                className="ticket-white"
                                src={TicketGold}
                                alt=""
                              />
                            </div>
                          </button>
                        </Link> */}
                          <button className="btn black-btn-trans-sold w-100">
                            <img
                              className="ticket-gold"
                              src={TicketBlack}
                              alt=""
                            />{" "}
                            Buy Ticket
                          </button>
                        </div>
                      </div>
                      <img className="soldout-img" src={SoldOut} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="position-soldout">
                      <div className="gold-section">
                        <div className="text-center">
                          <img className="silver-star" src={GoldStar} alt="" />
                          <h2 className="font-bold text-dark mt-3">Gold</h2>
                        </div>
                        <div className="bg-dark text-center py-3">
                          <h1 className="banner-white-text ">12,999</h1>
                          <p className="mb-0 banner-para-content font-bold">
                            + GST
                          </p>
                        </div>
                        <div className="silver-ticket-padding">
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Conference Kit</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Lunch day - 1</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Dinner day - 1</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Lunch day - 2</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Award night</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">
                              Video replay access of the event for 1 year
                            </p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">21 Days challenge June 2024</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">
                              1 Hour Private Session with Vishnu Hari
                            </p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickWrong}
                              alt=""
                            />
                            <p className="mb-0">
                              Photo Opportunity with influencers & vip
                            </p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickWrong}
                              alt=""
                            />
                            <p className="mb-0">
                              Lunch & Dinner with influencers & vip
                            </p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickWrong}
                              alt=""
                            />
                            <p className="mb-0">Red carpet welcome</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickWrong}
                              alt=""
                            />
                            <p className="">
                              1 Hour consulting with Dharaneetharan
                            </p>
                          </div>
                          <div>
                            <h6 className="mb-4">
                              Seating arrangement -{" "}
                              <span className="font-bold">RESERVED</span>
                            </h6>
                          </div>
                          {/* <Link
                          tnds
                          to="https://pages.razorpay.com/pl_NuarmkCDaFhlo1/view"
                          seyal
                          to="https://pages.razorpay.com/pl_O2Obf0Lnv15RnK/view"   
                        >
                          <button
                            className="btn black-btn-trans w-100"
                            data="Buy Ticket"
                          >
                            <div className="figure">
                              <img
                                className="ticket-gold"
                                src={TicketBlack}
                                alt=""
                              />
                              <img
                                className="ticket-white"
                                src={TicketGold}
                                alt=""
                              />
                            </div>
                          </button>
                        </Link> */}
                          <button className="btn black-btn-trans-sold w-100">
                            <img
                              className="ticket-gold"
                              src={TicketBlack}
                              alt=""
                            />{" "}
                            Buy Ticket
                          </button>
                        </div>
                      </div>
                      <img className="soldout-img" src={SoldOut} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="position-soldout">
                      <div className="diamond-section">
                        <div className="text-center">
                          <img
                            className="silver-star"
                            src={DiamondStar}
                            alt=""
                          />
                          <h2 className="font-bold text-dark">Diamond</h2>
                        </div>
                        <div className="bg-dark text-center py-3">
                          <h1 className="banner-white-text ">24,999</h1>
                          <p className="mb-0 banner-para-content font-bold ">
                            + GST
                          </p>
                        </div>
                        <div className="silver-ticket-padding">
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Conference Kit</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Lunch day - 1</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Dinner day - 1</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Lunch day - 2</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Award night</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">
                              Video replay access of the event for 1 year
                            </p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">21 Days challenge June 2024</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">
                              1 Hour Private Session with Vishnu Hari
                            </p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">
                              Photo Opportunity with influencers & vip
                            </p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">
                              Lunch & Dinner with influencers & vip
                            </p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="mb-0">Red carpet welcome</p>
                          </div>
                          <div className="silver-ticket-flex">
                            <img
                              className="ticket-buy-icon"
                              src={TickRight}
                              alt=""
                            />
                            <p className="">
                              1 Hour consulting with Dharaneetharan
                            </p>
                          </div>
                          <div>
                            <h6 className="mb-4">
                              Seating arrangement -{" "}
                              <span className="font-bold">FRONT SOFA</span>
                            </h6>
                          </div>
                          <button className="btn black-btn-trans-sold w-100">
                            <img
                              className="ticket-gold"
                              src={TicketBlack}
                              alt=""
                            />{" "}
                            Buy Ticket
                          </button>
                        </div>
                      </div>
                      <img className="soldout-img" src={SoldOut} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-11 mt-5 ">
                <div className="stroke-text-container mt-3 mb-5 d-flex justify-content-center">
                  <h1 className="bg-text-stroke">FAQ</h1>
                  <h1 className="gradient-text-heading ">
                    FREQUENTLY ASKED QUESTIONS
                  </h1>
                </div>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        What are the dates for the TN digital summit 2024?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        May 25-26 2024, Hilton, Chennai, Tamilnadu, India...
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Who is TN digital summit 2024 for?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Business owners, entrepreneurs, freelancers, agency
                        owners, coaches, consultants and marketers who want to
                        learn how to successfully scale their business to 8
                        figures in 2024 with proven strategies and frameworks.
                        Regardless of whatever you sell.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        What Is The Schedule?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        May 25 - 8AM to 6PM, May 26 - 9AM to 4.15PM
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Can I bring a spouse or business partner?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Absolutely, but everyone needs their own ticket!
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        Can I get a refund if I can’t attend the Live Event?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        The tickets purchased are non refundable. However the ticket can be transferred to your friends or family.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        What do I need to bring?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        A smile, a good attitude, and a passion for your own
                        offer, that’s it. Rest, I’ve got you covered :)
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        Where Is The Venue For The Event?
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        It’s In Hilton, Chennai.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        Will the replays be available?
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Replays are available with GOLD AND DIAMOND ticket
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        Have other questions?
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Contact us at flyhigh@socialeagle.in
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyTicketContent;
