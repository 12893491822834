import React, { useState, useEffect } from "react";
import AgendaHeadingBg from "../../assets/images/agenda-heading-bg.webp";
import A1 from "../../assets/images/a1.webp";
import A2 from "../../assets/images/a2.webp";
import A3 from "../../assets/images/a3.webp";
const AgendaContent = () => {
  return (
    <div>
      <div className="pt-3">
        <div className="">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 mb-3">
                <div className="agenda-pos-img">
                  <img
                    className="agenda-heading-img"
                    src={AgendaHeadingBg}
                    alt=""
                  />
                  <h2 className="agenda-heading mb-0">Day 1 - 25 May 2024</h2>
                </div>
              </div>
              <div className="col-lg-12 agenda-table-overflow ">
                <table className="w-100 agenda-table">
                  <tr>
                    <td className="w-time agenda-icon">
                      <img className="agenda-icon-img" src={A1} alt="" />
                    </td>
                    <td className="w-topic agenda-icon">
                      <img className="agenda-icon-img" src={A2} alt="" />
                    </td>
                    <td className="w-speaker agenda-icon">
                      <img className="agenda-icon-img" src={A3} alt="" />
                    </td>
                  </tr>
                  <tr className="agenda-first-row">
                    <td className="rounded-border-heading w-time ">
                      <p className="table-heading">Time</p>
                    </td>
                    <td className="rounded-border-heading w-topic">
                      <p className="table-heading">Topic</p>
                    </td>
                    <td className="rounded-border-heading w-speaker">
                      <p className="table-heading">
                        Speaker / Panel Discussion
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-yellow w-time ">
                      <p className="table-yellow-content">08:00 AM - 09:00 AM</p>
                    </td>
                    <td className="rounded-border-yellow w-speaker" colSpan={2}>
                      <p className="table-yellow-content">Registration</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">09:00 AM - 09:20 AM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                      Welcome Address & Opening Remarks
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start pt-3">
                        <span className="font-bold text-white">
                          G D Dharaneetharan{" "}
                        </span>
                        ( Founder, Social Eagle & 21DC Community )
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content pt-3 ">
                        09:20 AM - 10:05 AM
                      </p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content pt-3  text-start">
                        <span className="font-bold text-white">
                          The Unstoppable Force,{" "}
                        </span>
                        (preparing Yourself For The Now & Beyond)
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content  text-start pt-3">
                        <span className="font-bold text-white">
                          S.Muthuraman{" "}
                        </span>
                        ( Founder,Lakshmi Ceramics )
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">

                      <p className="table-para-content pt-3">
                        10:05 AM - 10:50 AM
                      </p>
                    </td>
                    <td className="rounded-border-content  w-topic">

                      <p className="table-para-content pt-3 text-start">
                        High Ticket Selling
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">


                      <p className="table-para-content text-start pt-3">
                        <span className="font-bold text-white">
                          Rohan Dhawan
                        </span>{" "}
                        ( Founder, UABILITY )
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-yellow w-time ">
                      <p className="table-yellow-content">
                        10:50 AM - 11:10 AM
                      </p>
                    </td>
                    <td className="rounded-border-yellow w-speaker" colSpan={2}>
                      <p className="table-yellow-content">BREAK</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">11:10 AM - 11:50 AM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                        The Deep Method of Marketing
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white text-start">
                          Deepak Kanakaraju (aka) Digital Deepak{" "}
                        </span>
                        <br /> Co-founder - PixelTrack
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">11:50 AM - 12:30 PM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content  text-start">
                        <span className=" font-bold text-white">Panel Discussion - 1</span> <br />
                        Impact through Value First Strategy
                      </p>

                      <p className="table-para-content text-start pt-3">
                        Moderated by{" "}
                        <span className="font-bold text-white">
                          Shatheeshram Ganapathy
                        </span>{" "}
                        <br />   
                        Head of Sales, Social Eagle
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start border-bottom">
                        <span className="font-bold text-white">
                          Khanthini Vijayakumar,{" "}
                        </span>{" "}
                        Psychologist
                      </p>
                      <p className="table-para-content text-start border-bottom pt-3">
                        <span className="font-bold text-white">
                          Maalica Ravikumar
                        </span>
                        <br /> Life Coach and Founder - EverGrowthAcademy
                      </p>
                      <p className="table-para-content text-start border-bottom pt-3">
                        <span className="font-bold text-white">
                          Jayanthi Rameshkumar
                        </span>
                        <br /> Founder - SmartKids Pregnency Caring Center
                      </p>
                      <p className="table-para-content text-start pt-3">
                        <span className="font-bold text-white">
                          Dr. Saravanan
                        </span>
                        <br /> Pediatrician , RKP Hospital
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-yellow w-time ">
                      <p className="table-yellow-content">
                        12:30 PM - 01:30 PM
                      </p>
                    </td>
                    <td className="rounded-border-yellow w-speaker" colSpan={2}>
                      <p className="table-yellow-content">LUNCH</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">01:30 PM - 02:00 PM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">

                      <p className="table-para-content  text-start">
                        <span className="font-bold text-white">
                          Panel Discussion - 2
                          <br />
                        </span>{" "}
                        Education through Content - The story of Passion, Impact
                        and Money
                      </p>
                      <p className="table-para-content text-start pt-3">
                        Moderated by{" "}
                        <span className="font-bold text-white">
                          Mohmed Abubacker
                        </span>{" "}
                        <br /> Founder at Traderoom & Co-Founder at Tint Tone &
                        Share
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content  text-start">
                        <span className="font-bold text-white">
                          Neeraj Elangovan{" "}
                        </span>
                        Founder,Uneevue Studios(OPC) Pvt.Ltd
                      </p>
                      <p className="table-para-content  text-start">
                        <span className="font-bold text-white">
                          Agnel John{" "}
                        </span>{" "}
                        Founder - Error makes clever
                      </p>
                      <p className="table-para-content pt-3 text-start">
                        <span className="font-bold text-white">
                          Mano Chandru{" "}
                        </span>{" "}
                        Founder - CleverCut
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">02:00 PM - 02:30 PM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                        The Power of Cash Back Funnel
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white text-start">
                          Gopal Krishnan{" "}
                        </span>
                        <br /> Creator of Binge Marketing
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-yellow w-time ">
                      <p className="table-yellow-content">
                        02:30 PM - 02:50 PM
                      </p>
                    </td>
                    <td className="rounded-border-yellow w-speaker" colSpan={2}>
                      <p className="table-yellow-content">BREAK</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">02:50 PM - 03:20 PM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                      Scaling using Flexi Funnel
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white text-start">
                        Karthik Ramani {" "}
                        </span>
                        <br /> Co-Founder, Flexifunnels
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">03:20 PM - 04:00 PM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                        Why should you Create Content?
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white text-start">
                          Dr. Santhosh Jacob{" "}
                        </span>
                        Orthopaedic & Sports Surgeon
                      
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-yellow w-time ">
                      <p className="table-yellow-content">
                        04:00 PM - 05:00 PM
                      </p>
                    </td>
                    <td className="rounded-border-yellow w-speaker" colSpan={2}>
                      <p className="table-yellow-content">NETWORKING BREAK	</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">05:00 PM - 05:20 PM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                      Impact Through Entrepreneurship
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white text-start">
                        Sivarajah Ramanathan{" "}
                        </span>
                        <br />               
                        Mission Director & CEO, Startup TN
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">05:20 PM - 06:05 PM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                      Special Address
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white text-start">
                        Dr. A. Velumani PhD{" "}
                        </span>
                        <br />
                        Creator, Thyrocare
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time bg-white">
                      <p className="table-para-content font-bold text-dark">
                        06:05 PM - 09.00 PM
                      </p>
                    </td>
                    <td
                      className="rounded-border-content w-speaker bg-white"
                      colSpan={2}
                    >
                      <p className="table-para-content text-dark font-bold">
                        AWARDS
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-yellow w-time ">
                      <p className="table-yellow-content">
                        09:00 PM {" "}<span className="text-capitalize">Onwards</span>
                      </p>
                    </td>
                    <td className="rounded-border-yellow w-speaker" colSpan={2}>
                      <p className="table-yellow-content">Networking Dinner</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-5 mt-5 mb-3">
                <div className="agenda-pos-img">
                  <img
                    className="agenda-heading-img"
                    src={AgendaHeadingBg}
                    alt=""
                  />
                  <h2 className="agenda-heading mb-0">Day 2 - 26 May 2024</h2>
                </div>
              </div>
              <div className="col-lg-12 agenda-table-overflow">
                <table className="w-100 agenda-table">
                  <tr>
                    <td className="w-time agenda-icon">
                      <img className="agenda-icon-img" src={A1} alt="" />
                    </td>
                    <td className="w-topic agenda-icon">
                      <img className="agenda-icon-img" src={A2} alt="" />
                    </td>
                    <td className="w-speaker agenda-icon">
                      <img className="agenda-icon-img" src={A3} alt="" />
                    </td>
                  </tr>
                  <tr className="agenda-first-row">
                    <td className="rounded-border-heading w-time ">
                      <p className="table-heading">Time</p>
                    </td>
                    <td className="rounded-border-heading w-topic">
                      <p className="table-heading">Topic</p>
                    </td>
                    <td className="rounded-border-heading w-speaker">
                      <p className="table-heading">
                        Speaker / Panel Discussion
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">09:00 AM - 09:30 AM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                        Personal Branding - Now and Beyond
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white text-start">
                          Sudharsanan Ganapathy{" "}
                        </span>
                        <br /> Founder - The Social Company
                      </p>
                    </td>
                  </tr>
                  <tr>

                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">09:30 AM - 10:00 AM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                      AI In Marketing
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white text-start">
                          Vishnuhari{" "}
                        </span>
                        <br /> Co-Founder - Social Eagle{" "}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">10:00 AM - 10:40 AM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white">
                          Panel Discussion - 3
                          <br />
                        </span>{" "}
                        From Click to Cart: Navigating E-commerce & D2C
                        Strategies
                      </p>
                      <p className="table-para-content text-start pt-3">
                        Moderated by{" "}
                        <span className="font-bold text-white">
                          Sakthivel Pannerselvam
                        </span>{" "}
                        <br /> Guerrilla Marketing Expert - Chief Surprise Ninja
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start border-bottom">
                        <span className="font-bold text-white">
                          Nivetha Muralidharan{" "}
                        </span>{" "}
                        Founder, Newgenmax Digital Solutions e-commerce Expert
                      </p>
                      <p className="table-para-content text-start mb-0 pt-3">
                        <span className="font-bold text-white">
                          Vijay S Raja
                        </span>{" "}
                        Co-founder - Three Dots Retail Founding partner - Thoovi
                        Arts
                      </p>
                      <p className="table-para-content text-start border-bottom pt-1">
                        <span className="font-bold text-white">
                          Nithya G
                        </span>{" "}
                        Founder - Habbada Fashions
                      </p>
                      <p className="table-para-content text-start pt-3">
                        <span className="font-bold text-white">Rajkumar</span>{" "}
                         Founder, Neidhal.com E-Commerce & Organic Marketing Expert
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-yellow w-time ">
                      <p className="table-yellow-content">
                        10:40 AM - 11:00 AM
                      </p>
                    </td>
                    <td className="rounded-border-yellow w-speaker" colSpan={2}>
                      <p className="table-yellow-content">BREAK</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">11:00 AM - 11:45 AM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                        7 Steps to become a Crorepati in 2024 - A Practical
                        Framework
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white text-start">
                          G D Dharaneetharan{" "}
                        </span>
                        <br /> Founder - Social Eagle & 21DC Community
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">11:45 AM - 01:00 PM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                        The SLOSHED FRAMEWORK 2.0 A step by step process to sell
                        High Ticket
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white text-start">
                          Pratham Sharma{" "}
                        </span>
                        <br /> Co-Founder , UABILITY
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-yellow w-time ">
                      <p className="table-yellow-content">
                        01:00 PM - 02:00 PM
                      </p>
                    </td>
                    <td className="rounded-border-yellow w-speaker" colSpan={2}>
                      <p className="table-yellow-content">LUNCH</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">02:00 PM - 02:40 PM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                        Reverse Funnel / Launch Funnel
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white text-start">
                          Shubh Jain{" "}
                        </span>
                        Launch Funnel Specialist $7M+ in Product Launch Sales
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">02:40 PM - 03:20 PM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                        Becoming Super Human
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white text-start">
                          Swastik Nandakumar{" "}
                        </span>
                        <br /> Peak Performance Coach
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">03:20 PM - 03:40 PM</p>
                    </td>

                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                        Fireside Chat with Raj Mohan
                      </p>

                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start border-bottom">
                        <span className="font-bold text-white">
                        Rajmohan Arumugam
                        </span>{" "}<br />
                        Film Director
                      </p>
                      <p className="table-para-content text-start pt-3">
                        <span className="font-bold text-white"> Venkatesh Narayanasamy</span>{" "}
                        Entrepreneur, Leader, Community Advocate
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time ">
                      <p className="table-para-content ">03:40 PM - 04:15 PM</p>
                    </td>
                    <td className="rounded-border-content  w-topic">
                      <p className="table-para-content text-start">
                      Entrepreneurship Mindset
                      </p>
                    </td>
                    <td className="rounded-border-content w-speaker">
                      <p className="table-para-content text-start">
                        <span className="font-bold text-white text-start">
                          C K Kumaravel {" "}
                        </span>
                        Co-Founder, Naturals{" "}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="rounded-border-content w-time bg-white">
                      <p className="table-para-content font-bold text-dark">
                        04:15 PM - 04:30 PM
                      </p>
                    </td>
                    <td
                      className="rounded-border-content w-speaker bg-white"

                    >
                      <p className="table-para-content text-dark font-bold">

                        VALEDICTORY SESSION
                      </p>
                    </td>
                    <td
                      className="rounded-border-content w-speaker bg-white"

                    >
                      <p className="table-para-content text-dark text-start">
                        <span className="font-bold ">
                          G D Dharaneetharan {" "}
                        </span> <br/>
                        Founder - Social Eagle & 21DC Community
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgendaContent;
