import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import key from "../../assets/images/key.webp";
import ThankYouModal from "../Modal/ThankyouModal";
const NominationForm = () => {
  const location = useLocation();
  useEffect(() => {
    const { search } = location;
    if (!search) {
      window.scroll(0, 0);
    }
  }, []);

  //select radio btn start
  const initialSelectedBox = "";
  const [selectedBox, setSelectedBox] = useState(initialSelectedBox);
  const handleRadioButton = (boxId) => {
    setSelectedBox(boxId);
  };

  const [value, setValue] = useState({});
  const [validation, setValidation] = useState({});
  const [thankYouModal, setThankYouModal] = useState(false);
  const [loading, setLoading] = useState(false);

  function isEmail(email) {
    return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  const generateRandomString = (length) => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      randomString += chars.charAt(randomIndex);
    }
    return randomString;
  };
  const [captchaText, setCaptchaText] = useState(generateRandomString(6));
  const [userInput, setUserInput] = useState("");
  const [isValid, setIsValid] = useState(true);

  const regenerateCaptcha = () => {
    setCaptchaText(generateRandomString(6));
    setUserInput("");
    setIsValid(true);
  };
  const handleInputChange = (event) => {
    setUserInput(event.target.value);
    setIsValid(true);
  };

  const CheckValidation = () => {
    let Status = true,
      validdateData = {};

    if (!value.name || !value.name?.length) {
      validdateData.name = "Name is Required";
      Status = false;
    } else if (!isNaN(value.name)) {
      validdateData.name = "Name is invalid";
      Status = false;
    } else if (value.name === undefined) {
      validdateData.name = "Name is Required";
      Status = false;
    } else if (value.name === "") {
      validdateData.name = "Name is Required";
      Status = false;
    } else if (value.name.length <= 1) {
      validdateData.name = "Name must be maximum 2 letters";
      Status = false;
    }

    if (!value.mobileNumber === undefined) {
      validdateData.mobileNumber = "Mobile Number is Required";
      Status = false;
    } else if (!value.mobileNumber?.toString().length) {
      validdateData.mobileNumber = "Mobile Number is Required";
      Status = false;
    } else if (value.mobileNumber.toString().length !== 10) {
      validdateData.mobileNumber = "Mobile Number must be 10 digits";
      Status = false;
    }
    if (!value.email?.trim()?.length) {
      validdateData.email = "Email is Required";
      Status = false;
    } else if (value.email?.includes(" ")) {
      validdateData.email = "Email cannot include spaces";
      Status = false;
    } else if (value.email && isEmail(value.email)) {
      validdateData.email = "Email is Invalid";
      Status = false;
    }

    if (!value.city) {
      validdateData.city = "City is Required";
      Status = false;
    }
    if (!value.state) {
      validdateData.state = "State is Required";
      Status = false;
    }
    if (!value.linkedIN) {
      validdateData.linkedIN = "LinkedIN Url is Required";
      Status = false;
    }
    if (!value.linkedINFollowerCount) {
      validdateData.linkedINFollowerCount =
        "LinkedIN Follower Count is Required";
      Status = false;
    }
    if (!value.instagram) {
      validdateData.instagram = "Instagram Url is Required";
      Status = false;
    }
    if (!value.instagramFollowerCount) {
      validdateData.instagramFollowerCount =
        "Instagram Follower Count is Required";
      Status = false;
    }
    // if (!value.x) {
    //     validdateData.x = "X Url is Required";
    //     Status = false;
    // }
    // if (!value.xFollowerCount) {
    //     validdateData.xFollowerCount = "X Follower Count is Required";
    //     Status = false;
    // }
    if (!value.facebook) {
      validdateData.facebook = "Facebook Url is Required";
      Status = false;
    }
    if (!value.facebookFollowerCount) {
      validdateData.facebookFollowerCount =
        "Facebook Follower Count is Required";
      Status = false;
    }
    if (!value.youTube) {
      validdateData.youTube = "YouTube Url is Required";
      Status = false;
    }
    if (!value.youTubeINFollowerCount) {
      validdateData.youTubeINFollowerCount =
        "YouTube Follower Count is Required";
      Status = false;
    }
    if (!value.nomineedescription) {
      validdateData.nomineedescription = "Nominee Description is Required";
      Status = false;
    }
    if (!value.nomineesworks) {
      validdateData.nomineesworks = "Nominee Works is Required";
      Status = false;
    }
    if (!value.describethecorethemeofyourcontent) {
      validdateData.describethecorethemeofyourcontent = "This is Required";
      Status = false;
    }
    if (!value.whatuniquevalueyoubringfollowers) {
      validdateData.whatuniquevalueyoubringfollowers = "This is Required";
      Status = false;
    }
    if (!value.canyoushareaspecificinstance) {
      validdateData.canyoushareaspecificinstance = "This is Required";
      Status = false;
    }
    if (!value.howdoyoumeasurethesuccess) {
      validdateData.howdoyoumeasurethesuccess = "This is Required";
      Status = false;
    }
    if (!value.discussachallenge) {
      validdateData.discussachallenge = "This is Required";
      Status = false;
    }
    if (!value.howdoyouensuretheauthenticity) {
      validdateData.howdoyouensuretheauthenticity = "This is Required";
      Status = false;
    }
    if (!value.influencerCategory > 0) {
      validdateData.influencerCategory = "Please select the option";
    }
    // if (!value.bestdigitalagency > 0) {
    //     validdateData.bestdigitalagency = "Please select the option";
    // }
    if (userInput === "") {
      validdateData.userInput = "Please Enter Captcha";
    }
    setValidation({ ...validation, ...validdateData });
    return Status;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let inputvalue = {
      Name1: value.name,
      MobileNumber1: value.mobileNumber,
      Email1: value.email,
      City1: value.city,
      State1: value.state,
      LinkedinURL1: value.linkedIN,
      LinkedinFollowersCount1: value.linkedINFollowerCount,
      InstagramURL1: value.instagram,
      InstagramFollowerCount1: value.instagramFollowerCount,
      // XURL: value.x,
      // XFollowerCount: value.xFollowerCount,
      FacebookURL1: value.facebook,
      FacebookFollowerCount1: value.facebookFollowerCount,
      YouTubeURL1: value.youTube,
      YouTubeINFollowerCount1: value.youTubeINFollowerCount,
      NomineeDescription1: value.nomineedescription,
      Nomineesworks1: value.nomineesworks,
      Describethecorethemeofyourcontent:
        value.describethecorethemeofyourcontent,
      Whatuniquevalueyoubringfollowers: value.whatuniquevalueyoubringfollowers,
      Canyoushareaspecificinstance: value.canyoushareaspecificinstance,
      Howdoyoumeasurethesuccess: value.howdoyoumeasurethesuccess,
      Discussachallenge: value.discussachallenge,
      Howdoyouensuretheauthenticity: value.howdoyouensuretheauthenticity,
      BestInfluencerCategory: value.influencerCategory,
      Date1: new Date(),
      // BestDigitalAgency: value.bestdigitalagency,
      Location1: window.location.href,
    };
    let formdata = new FormData();
    for (let key in inputvalue) {
      formdata.append(key, inputvalue[key]);
    }
    if (CheckValidation()) {
      try {
        await axios
          .post(
            // "https://script.google.com/macros/s/AKfycbyu2sVopZ5EzqxyfIH2HIG7bnpW5rsr2oxecCYEKoXIQ5nbTZ8SXLucVhSjPoct0zTVrg/exec",
            "https://script.google.com/macros/s/AKfycbxL_XqHGdGx6AqcEC6PJbwym5Lbcl0h1eq8yhBkEFl_8wxSfJTkcX0vW76QJgkzJnozBQ/exec",
            formdata
          )
          .then(() => {
            setValue({});
            setThankYouModal(true);
            e.target.reset();
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const setData = (e, key) => {
    setValue({ ...value, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };
  return (
    <div className="bg-agenda">
      <Header />
      <ThankYouModal
        open={thankYouModal}
        setOpen={() => {
          setThankYouModal(false);
        }}
      />
      <div className="nomination-container">
        <div className="digital-summit-heading">
          <div className="container">
            <div className="stroke-text-container d-flex justify-content-center">
              <h1 className="bg-text-stroke grey-text-stroke">Nomination</h1>
              <h1 className="gradient-text-heading-yellow text-white">
                Submit Your Nomination
              </h1>
            </div>
          </div>
        </div>
        <div className="container nomination-form-container">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="mb-3">
                  <div className="text-center mb-3">
                    <h3 className="gradient-text">Contact Information</h3>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div class="nomination-form-field">
                        <h6 for="name" class="form-label ">
                          Name*
                        </h6>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          class="form-control"
                          placeholder="Enter Name"
                          onChange={(e) => {
                            setData(e.target.value, "name");
                          }}
                        />
                        {validation.name && (
                          <span className="validationmsg small">
                            {validation.name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div class="nomination-form-field">
                        <h6 for="email" class="form-label ">
                          Email*
                        </h6>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          class="form-control"
                          placeholder="Enter Email"
                          onChange={(e) => {
                            setData(e.target.value, "email");
                          }}
                        />
                        {validation.email && (
                          <span className="validationmsg small">
                            {validation.email}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div class="nomination-form-field">
                        <h6 for="phoneNumber" class="form-label ">
                          Phone Number*
                        </h6>
                        <input
                          type="number"
                          id="phoneNumber"
                          name="mobileNumber"
                          class="form-control"
                          placeholder="Enter Phone Number"
                          onChange={(e) => {
                            setData(e.target.value, "mobileNumber");
                          }}
                        />
                        {validation.mobileNumber && (
                          <span className="validationmsg small">
                            {validation.mobileNumber}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div class="nomination-form-field">
                        <h6 for="city" class="form-label ">
                          City*
                        </h6>
                        <input
                          type="text"
                          id="city"
                          name="city"
                          class="form-control"
                          placeholder="Enter City"
                          onChange={(e) => {
                            setData(e.target.value, "city");
                          }}
                        />
                        {validation.city && (
                          <span className="validationmsg small">
                            {validation.city}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div class="nomination-form-field">
                        <h6 for="state" class="form-label ">
                          State
                        </h6>
                        <input
                          type="text"
                          id="state"
                          name="state"
                          class="form-control"
                          placeholder="Enter State"
                          onChange={(e) => {
                            setData(e.target.value, "state");
                          }}
                        />
                        {validation.state && (
                          <span className="validationmsg small">
                            {validation.state}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="mb-4">
                  <div className="mb-3">
                    <h3 className="gradient-text text-center mb-3">
                      Category for Nomination{" "}
                    </h3>
                    {/* <h6 className="counter-text">25+ BEST CONTENT & INFLUENCER CATEGORIES</h6> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div class="nomination-form-field">
                        <>
                          <h6 htmlFor="designation" className="form-label">
                            25+ Best Content & Influencer Categories*
                          </h6>
                          <select
                            onChange={(e) => {
                              setData(e.target.value, "influencerCategory");
                            }}
                            className="form-select form-control"
                          >
                            <option>--- Select one option ---</option>
                            <option value={"Food"}>Food</option>
                            <option value="Travel">Travel</option>
                            <option value="Lifestyle">Lifestyle</option>
                            <option value="Fitness">Fitness</option>
                            <option value="Health">Health</option>
                            <option value="Cooking">Cooking</option>
                            <option value="Comedy">Comedy</option>
                            <option value="Mindset & Psychology">
                              Mindset & Psychology
                            </option>
                            <option value="Life Coach">Life Coach</option>
                            <option value="Coaches (Other)">
                              Coaches (Other)
                            </option>
                            <option value="Finance">Finance</option>
                            <option value="Tech Educators">
                              Tech Educators
                            </option>
                            <option value="Professionals(Doctors, Lawyers & etc)">
                              Professionals(Doctors, Lawyers & etc)
                            </option>
                            <option value="Entrepreneurs & Business People">
                              Entrepreneurs & Business People
                            </option>
                            <option value="Best Storyteller">
                              Best Storyteller
                            </option>
                            <option value="The Disruptor of the year">
                              The Disruptor of the year
                            </option>
                            <option value="Most Impactful Agri Creator">
                              Most Impactful Agri Creator
                            </option>
                            <option value="Gaming">Gaming</option>
                            <option value="Tech Creator - Technology & Hardware">
                              Tech Creator - Technology & Hardware
                            </option>
                            <option value="Quality Producton (Tech) - Video & Edits">
                              Quality Production (Tech) - Video & Edits
                            </option>
                            <option value="Beauty & Fashine">
                              Beauty & Fashion
                            </option>
                            <option value="Couple Influencer of the Year">
                              Couple Influencer of the Year
                            </option>
                            <option value="Positive Impact Creators / Shining Stars">
                              Positive Impact Creators / Shining Stars
                            </option>
                            <option value="Gaming">
                              Social Service / Humanity Award
                            </option>
                            <option value="Auto-Motive Influencer">
                              Auto-Motive Influencer
                            </option>
                            <option value="Entertainer of the Year">
                              Entertainer of the Year
                            </option>
                            <option value="Celebrity Creator of the year">
                              Celebrity Creator of the year
                            </option>
                            <option value="Nano Influencer of the year">
                              Nano Influencer of the year
                            </option>
                          </select>
                          {validation.influencerCategory && (
                            <span className="validationmsg small">
                              {validation.influencerCategory}
                            </span>
                          )}
                        </>
                      </div>
                    </div>
                    {/* <div className="col-lg-12">
                                            <div class="nomination-form-field">
                                                <>
                                                    <h6 htmlFor="designation" className="form-label">
                                                        Best Digital Agency Awards*
                                                    </h6>
                                                    <select
                                                        onChange={(e) => {
                                                            setData(e.target.value, "bestdigitalagency");
                                                        }}
                                                        className="form-select form-control"
                                                    >
                                                        <option>--- Select one option ---</option>
                                                        <option value={"Campaign Effectiveness Award"}>Campaign Effectiveness Award</option>
                                                        <option value="Best use of AI in client Campaign">Best use of AI in client Campaign</option>
                                                        <option value="Best PR Campaign">Best PR Campaign</option>
                                                        <option value="Best PPC Campaign">Best PPC Campaign</option>
                                                        <option value="Best Social Media Campaign">Best Social Media Campaign</option>
                                                        <option value="Best Creative Campaign - Agency Award">Best Creative Campaign - Agency Award</option>
                                                        <option value="Best Digital Start-Up of the year">Best Digital Start-Up of the year</option>
                                                    </select>
                                                    {validation.bestdigitalagency && (
                                                        <span className="validationmsg small">{validation.bestdigitalagency}</span>
                                                    )}
                                                </>
                                            </div>
                                        </div> */}
                  </div>
                  {/* <div className="trading-radio mb-3">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Food"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Food")}
                                                    >
                                                        <label className="form-check-label">
                                                            Food
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked
                                                            checked={selectedBox === "Food"}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Travel"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Travel")}
                                                    >
                                                        <label className="form-check-label">
                                                            Travel
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Travel"}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Lifestyle"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Lifestyle")}
                                                    >
                                                        <label className="form-check-label">
                                                            Lifestyle
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Lifestyle"}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Fitness"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Fitness")}
                                                    >
                                                        <label className="form-check-label">
                                                            Fitness
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Fitness"}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Health"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Health")}
                                                    >
                                                        <label className="form-check-label">
                                                            Health
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Health"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Cooking"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Cooking")}
                                                    >
                                                        <label className="form-check-label">
                                                            Cooking
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Cooking"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Comedy"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Comedy")}
                                                    >
                                                        <label className="form-check-label">
                                                            Comedy
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Comedy"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Mindset & Psychology"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Mindset & Psychology")}
                                                    >
                                                        <label className="form-check-label">
                                                            Mindset & Psychology
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Mindset & Psychology"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Life Coach"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Life Coach")}
                                                    >
                                                        <label className="form-check-label">
                                                            Life Coach
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Life Coach"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Coaches (Other)"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Coaches (Other)")}
                                                    >
                                                        <label className="form-check-label">
                                                            Coaches (Other)
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Coaches (Other)"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Finance"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Finance")}
                                                    >
                                                        <label className="form-check-label">
                                                            Finance
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Finance"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Tech Educators"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Tech Educators")}
                                                    >
                                                        <label className="form-check-label">
                                                            Tech Educators
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Tech Educators"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Professionals(Doctors, Lawyers & etc)"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Professionals(Doctors, Lawyers & etc)")}
                                                    >
                                                        <label className="form-check-label">
                                                            Professionals(Doctors, Lawyers & etc)
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Professionals(Doctors, Lawyers & etc)"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Entrepreneurs & Business People"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Entrepreneurs & Business People")}
                                                    >
                                                        <label className="form-check-label">
                                                            Entrepreneurs & Business People
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Entrepreneurs & Business People"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Best Storyteller"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Best Storyteller")}
                                                    >
                                                        <label className="form-check-label">
                                                            Best Storyteller
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Best Storyteller"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "The Disruptor of the year"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("The Disruptor of the year")}
                                                    >
                                                        <label className="form-check-label">
                                                            The Disruptor of the year
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "The Disruptor of the year"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Most Impactful Agri Creator"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Most Impactful Agri Creator")}
                                                    >
                                                        <label className="form-check-label">
                                                            Most Impactful Agri Creator
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Most Impactful Agri Creator"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Gaming"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Gaming")}
                                                    >
                                                        <label className="form-check-label">
                                                            Gaming
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Gaming"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Tech Creator - Technology & Hardware"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Tech Creator - Technology & Hardware")}
                                                    >
                                                        <label className="form-check-label">
                                                            Tech Creator - Technology & Hardware
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Tech Creator - Technology & Hardware"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Quality Producton (Tech) - Video & Edits"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Quality Producton (Tech) - Video & Edits")}
                                                    >
                                                        <label className="form-check-label">
                                                            Quality Producton (Tech) - Video & Edits
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Quality Producton (Tech) - Video & Edits"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Beauty & Fashine"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Beauty & Fashine")}
                                                    >
                                                        <label className="form-check-label">
                                                            Beauty & Fashine
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Beauty & Fashine"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Couple Influencer of the Year"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Couple Influencer of the Year")}
                                                    >
                                                        <label className="form-check-label">
                                                            Couple Influencer of the Year
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Couple Influencer of the Year"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Positive Impact Creators / Shining Stars"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Positive Impact Creators / Shining Stars")}
                                                    >
                                                        <label className="form-check-label">
                                                            Positive Impact Creators / Shining Stars
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Positive Impact Creators / Shining Stars"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Social Service / Humanity Award"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Social Service / Humanity Award")}
                                                    >
                                                        <label className="form-check-label">
                                                            Social Service / Humanity Award
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Social Service / Humanity Award"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Auto-Motive Influencer"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Auto-Motive Influencer")}
                                                    >
                                                        <label className="form-check-label">
                                                            Auto-Motive Influencer
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Auto-Motive Influencer"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Entertainer of the Year"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Entertainer of the Year")}
                                                    >
                                                        <label className="form-check-label">
                                                            Entertainer of the Year
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Entertainer of the Year"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Celebrity Creator of the year"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Celebrity Creator of the year")}
                                                    >
                                                        <label className="form-check-label">
                                                            Celebrity Creator of the year
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Celebrity Creator of the year"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Nano Influencer of the year"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Nano Influencer of the year")}
                                                    >
                                                        <label className="form-check-label">
                                                            Nano Influencer of the year
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked
                                                            checked={selectedBox === "Nano Influencer of the year"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className="counter-text mb-3">BEST DIGITAL AGENCY AWARDS</h6>
                                    </div>
                                    <div className="trading-radio">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Campaign Effectiveness Award"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Campaign Effectiveness Award")}
                                                    >
                                                        <label className="form-check-label">
                                                            Campaign Effectiveness Award
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked
                                                            checked={selectedBox === "Campaign Effectiveness Award"}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Best use of AI in client Campaign"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Best use of AI in client Campaign")}
                                                    >
                                                        <label className="form-check-label">
                                                            Best use of AI in client Campaign
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Best use of AI in client Campaign"}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Best PR Campaign"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Best PR Campaign")}
                                                    >
                                                        <label className="form-check-label">
                                                            Best PR Campaign
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Best PR Campaign"}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Best PPC Campaign"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Best PPC Campaign")}
                                                    >
                                                        <label className="form-check-label">
                                                            Best PPC Campaign
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Best PPC Campaign"}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Best Social Media Campaign"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Best Social Media Campaignh")}
                                                    >
                                                        <label className="form-check-label">
                                                            Best Social Media Campaign
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Best Social Media Campaign"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Best Creative Campaign - Agency Award"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Best Creative Campaign - Agency Award")}
                                                    >
                                                        <label className="form-check-label">
                                                            Best Creative Campaign - Agency Award
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked

                                                            checked={selectedBox === "Best Creative Campaign - Agency Award"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-6">
                                                <div className="form-check">
                                                    <div
                                                        className={`box ${selectedBox === "Best Digital Start-Up of the year"
                                                            ? "rounded-check"
                                                            : "rounded-notcheck"
                                                            }`}
                                                        onClick={() => handleRadioButton("Best Digital Start-Up of the year")}
                                                    >
                                                        <label className="form-check-label">
                                                            Best Digital Start-Up of the year
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="exampleRadios"
                                                            id="exampleRadios1"
                                                            value="option1"
                                                            defaultChecked
                                                            checked={selectedBox === "Best Digital Start-Up of the year"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {validation.selectedBox && (
                                        <span className="validationmsg small">Please select any one option</span>
                                    )} */}
                </div>
              </div>
              <div className="col-lg-10">
                <div className="mb-3">
                  <div className="text-center mb-3">
                    <h3 className="gradient-text mb-3">
                      Social Media Handles / Follower Count
                    </h3>
                    <h6 class="na-content mb-4">
                        ( Please type “NA” for fields/URLs not applicable to you )
                    </h6>
                  </div>
                  <div className="row">
                    <div className="col-lg-8">
                     <div class="nomination-form-field">
                        <h6 for="linkedIN" class="form-label ">
                          LinkedIN*{" "}
                        </h6>
                        <input
                          type="text"
                          id="linkedIN"
                          name="linkedIN"
                          class="form-control"
                          onChange={(e) => {
                            setData(e.target.value, "linkedIN");
                          }}
                          placeholder="Enter Profile URLs"
                        />
                        {validation.linkedIN && (
                          <span className="validationmsg small">
                            {validation.linkedIN}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div class="nomination-form-field">
                        <h6 for="linkedINFollowerCount" class="form-label ">
                          LinkedIN / Follower Count*
                        </h6>
                        <input
                          type="text"
                          id="linkedINFollowerCount"
                          name="linkedINFollowerCount"
                          class="form-control"
                          placeholder="Enter Count"
                          onChange={(e) => {
                            setData(e.target.value, "linkedINFollowerCount");
                          }}
                        />
                        {validation.linkedINFollowerCount && (
                          <span className="validationmsg small">
                            {validation.linkedINFollowerCount}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div class="nomination-form-field">
                        <h6 for="Instagram" class="form-label ">
                          Instagram*
                        </h6>
                        <input
                          type="text"
                          id="Instagram"
                          name="instagram"
                          class="form-control"
                          placeholder="Enter Profile URLs"
                          onChange={(e) => {
                            setData(e.target.value, "instagram");
                          }}
                        />
                        {validation.instagram && (
                          <span className="validationmsg small">
                            {validation.instagram}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div class="nomination-form-field">
                        <h6 for="InstagramINFollowerCount" class="form-label ">
                          Instagram / Follower Count*
                        </h6>
                        <input
                          type="text"
                          id="InstagramFollowerCount"
                          onChange={(e) => {
                            setData(e.target.value, "instagramFollowerCount");
                          }}
                          name="instagramFollowerCount"
                          class="form-control"
                          placeholder="Enter Count"
                        />
                        {validation.instagramFollowerCount && (
                          <span className="validationmsg small">
                            {validation.instagramFollowerCount}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-lg-8">
                                            <div class="nomination-form-field">
                                                <h6 for="X" class="form-label ">X*</h6>
                                                <input type="text" id="X" class="form-control"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "x");
                                                    }} name="x" placeholder="Enter Profile URLs" />
                                                {validation.x && (
                                                    <span className="validationmsg small">{validation.x}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div class="nomination-form-field">
                                                <h6 for="XFollowerCount" class="form-label ">X / Follower Count*</h6>
                                                <input type="text" id="xFollowerCount"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "xFollowerCount");
                                                    }}
                                                    name="xFollowerCount" class="form-control" placeholder="Enter Count" />
                                                {validation.xFollowerCount && (
                                                    <span className="validationmsg small">{validation.xFollowerCount}</span>
                                                )}
                                            </div>
                                        </div> */}
                    <div className="col-lg-8">
                      <div class="nomination-form-field">
                        <h6 for="Facebook" class="form-label ">
                          Facebook*
                        </h6>
                        <input
                          type="text"
                          id="Facebook"
                          name="facebook"
                          onChange={(e) => {
                            setData(e.target.value, "facebook");
                          }}
                          class="form-control"
                          placeholder="Enter Profile URLs"
                        />
                        {validation.facebook && (
                          <span className="validationmsg small">
                            {validation.facebook}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div class="nomination-form-field">
                        <h6 for="FacebookFollowerCount" class="form-label ">
                          Facebook / Follower Count*
                        </h6>
                        <input
                          type="text"
                          id="FacebookFollowerCount"
                          onChange={(e) => {
                            setData(e.target.value, "facebookFollowerCount");
                          }}
                          name="facebookFollowerCount"
                          class="form-control"
                          placeholder="Enter Count"
                        />
                        {validation.facebookFollowerCount && (
                          <span className="validationmsg small">
                            {validation.facebookFollowerCount}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div class="nomination-form-field">
                        <h6 for="YouTube" class="form-label ">
                          You Tube*{" "}
                        </h6>
                        <input
                          type="text"
                          id="YouTube"
                          name="youTube"
                          onChange={(e) => {
                            setData(e.target.value, "youTube");
                          }}
                          class="form-control"
                          placeholder="Enter Profile URLs"
                        />
                        {validation.youTube && (
                          <span className="validationmsg small">
                            {validation.youTube}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div class="nomination-form-field">
                        <h6 for="YouTubeFollowerCount" class="form-label ">
                          You Tube / Follower Count*
                        </h6>
                        <input
                          type="text"
                          id="YouTubeINFollowerCount"
                          onChange={(e) => {
                            setData(e.target.value, "youTubeINFollowerCount");
                          }}
                          name="youTubeINFollowerCount"
                          class="form-control"
                          placeholder="Enter Count"
                        />
                        {validation.youTubeINFollowerCount && (
                          <span className="validationmsg small">
                            {validation.youTubeINFollowerCount}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="mb-3">
                  <div className="text-center mb-3">
                    <h3 className="gradient-text">Short Bio</h3>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div class="nomination-form-field">
                        <h6 for="nominee" class="form-label ">
                          A brief description of the nominee*
                        </h6>
                        <textarea
                          class="form-control"
                          name="nomineedescription"
                          onChange={(e) => {
                            setData(e.target.value, "nomineedescription");
                          }}
                          placeholder="Enter Short Bio"
                          id="nominee"
                          rows="3"
                        ></textarea>
                        {validation.nomineedescription && (
                          <span className="validationmsg small">
                            {validation.nomineedescription}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="mb-3">
                  <div className="text-center mb-3">
                    <h3 className="gradient-text">
                      Portfolio or Content Samples
                    </h3>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div class="nomination-form-field">
                        <h6 for="nominee’sworks" class="form-label ">
                          My Portfolio*
                        </h6>
                        <input
                          type="text"
                          id="nominee’sworks"
                          onChange={(e) => {
                            setData(e.target.value, "nomineesworks");
                          }}
                          name="nomineesworks"
                          class="form-control"
                          placeholder="Enter Portfolio Links"
                        />
                        {validation.nomineesworks && (
                          <span className="validationmsg small">
                            {validation.nomineesworks}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="mb-5">
                  <div className="text-center mb-4">
                    <h3 className="gradient-text">
                      Answer the below Questions
                    </h3>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div class="nomination-form-field">
                        <h6 class="form-label ">
                          Describe the core theme of your content. How does it
                          resonate with your audience?*
                        </h6>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => {
                            setData(
                              e.target.value,
                              "describethecorethemeofyourcontent"
                            );
                          }}
                          name="describethecorethemeofyourcontent"
                          placeholder="Enter Answer"
                        />
                        {validation.describethecorethemeofyourcontent && (
                          <span className="validationmsg small">
                            {validation.describethecorethemeofyourcontent}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div class="nomination-form-field">
                        <h6 class="form-label ">
                          What unique value do you bring to your followers
                          compared to other creators in your category?*
                        </h6>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => {
                            setData(
                              e.target.value,
                              "whatuniquevalueyoubringfollowers"
                            );
                          }}
                          name="whatuniquevalueyoubringfollowers"
                          placeholder="Enter Answer"
                        />
                        {validation.whatuniquevalueyoubringfollowers && (
                          <span className="validationmsg small">
                            {validation.whatuniquevalueyoubringfollowers}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div class="nomination-form-field">
                        <h6 class="form-label ">
                          Can you share a specific instance where your work
                          significantly benefitted someone or made a positive
                          change?*
                        </h6>
                        <input
                          type="text"
                          class="form-control"
                          name="canyoushareaspecificinstance"
                          onChange={(e) => {
                            setData(
                              e.target.value,
                              "canyoushareaspecificinstance"
                            );
                          }}
                          placeholder="Enter Answer"
                        />
                        {validation.canyoushareaspecificinstance && (
                          <span className="validationmsg small">
                            {validation.canyoushareaspecificinstance}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div class="nomination-form-field">
                        <h6 class="form-label ">
                          How do you measure the success of your content, and
                          can you share any key metrics from a recent campaign?*
                        </h6>
                        <input
                          type="text"
                          class="form-control"
                          name="howdoyoumeasurethesuccess"
                          onChange={(e) => {
                            setData(
                              e.target.value,
                              "howdoyoumeasurethesuccess"
                            );
                          }}
                          placeholder="Enter Answer"
                        />
                        {validation.howdoyoumeasurethesuccess && (
                          <span className="validationmsg small">
                            {validation.howdoyoumeasurethesuccess}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div class="nomination-form-field">
                        <h6 class="form-label ">
                          Discuss a challenge you've encountered in content
                          creation and how you overcame it?*
                        </h6>
                        <input
                          type="text"
                          class="form-control"
                          name="discussachallenge"
                          onChange={(e) => {
                            setData(e.target.value, "discussachallenge");
                          }}
                          placeholder="Enter Answer"
                        />
                        {validation.discussachallenge && (
                          <span className="validationmsg small">
                            {validation.discussachallenge}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div class="nomination-form-field">
                        <h6 class="form-label ">
                          How do you ensure the authenticity and integrity of
                          your content while collaborating with brands?*
                        </h6>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => {
                            setData(
                              e.target.value,
                              "howdoyouensuretheauthenticity"
                            );
                          }}
                          name="howdoyouensuretheauthenticity"
                          placeholder="Enter Answer"
                        />
                        {validation.howdoyouensuretheauthenticity && (
                          <span className="validationmsg small">
                            {validation.howdoyouensuretheauthenticity}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div class="nomination-form-field">
                        <div className="row">
                          <div className="col-lg-10 col-md-8 col-sm-8 col-7">
                            <div className="inputimg form-control">
                              <img className="icon-key" src={key} alt="User" />
                              <input
                                type="text"
                                className="input-field-text"
                                placeholder="Secutiry Text"
                                value={userInput}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-4 col-5 p-0">
                            <div className="captchabox container">
                              <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-8 col-8 p-0">
                                  <span className="captchasize unselectable">
                                    {captchaText}
                                  </span>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                  <span
                                    className="material-symbols-outlined captchbutton"
                                    onClick={regenerateCaptcha}
                                  >
                                    cached
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {validation.userInput && (
                          <span className="validationmsg small">
                            {validation.userInput}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="nomination-btn text-center">
                        <button
                          class={`btn rounded font-bold yellow-btn w-25   ${loading ? "sending" : ""
                            } `}
                        >
                          {loading ? (
                            <div className="spinner-border" role="status">
                              <span className="sr-only ">Loading...</span>
                            </div>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NominationForm;
