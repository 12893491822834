import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PayTick from "../assets/images/pay-tick.webp"
const ThankYouPage = () => {


    return (
        <div>
            <div className="thankyou-page-container">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="">
                                <div className="modal-content">

                                    <div className="th-cnt">
                                        <img className="" src={PayTick} alt=""></img>
                                        <h1 className="font-bold mt-3">Success</h1>
                                        <p>We are delighted to inform you that we received your payment.</p>
                                        {/* <Link to="/">
                                            <button class="btn yellow-btn" data="Back to Home">
                                            </button>
                                        </Link> */}
                                        <a href="https://www.instagram.com/tndigitalsummit/?igsh=dnRsaGgxZ2RxajVk" target='_blank' className='d-flex thnk-page-follow items-center'>
                                            <h6 className=' gradient-text mt-1 mb-0'>Follow Us for More Update :</h6>
                                            <span className='fs-5 gradient-text px-2 py-0 mb-0'><i class="fa-brands fa-instagram"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThankYouPage;
