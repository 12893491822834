
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import AwardsBanner from "../Components/Awards/AwardsBanner";
import AwardsContent from "../Components/Awards/AwardsContent";

const Awards = () => {
    const location = useLocation();
    useEffect(() => {
      const { search } = location;
      if (!search) {
        window.scroll(0, 0);
      }
    }, []);
    return (
        <div className="bg-agenda">
            <Header />
            <AwardsBanner/>
            <AwardsContent/>
            <Footer />
        </div>
    );
};

export default Awards;
