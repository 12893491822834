
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import key from '../../assets/images/key.webp'
import ThankYouModal from "../Modal/ThankyouModal";
const NominationAgencyAward = () => {
    const location = useLocation();
    useEffect(() => {
        const { search } = location;
        if (!search) {
            window.scroll(0, 0);
        }
    }, []);

    //select radio btn start
    const initialSelectedBox = "";
    const [selectedBox, setSelectedBox] = useState(initialSelectedBox);
    const handleRadioButton = (boxId) => {
        setSelectedBox(boxId);
    };


    const [value, setValue] = useState({});
    const [validation, setValidation] = useState({});
    const [thankYouModal, setThankYouModal] = useState(false);
    const [loading, setLoading] = useState(false);

    function isEmail(email) {
        return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    }

    const generateRandomString = (length) => {
        const chars =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let randomString = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            randomString += chars.charAt(randomIndex);
        }
        return randomString;
    };
    const [captchaText, setCaptchaText] = useState(generateRandomString(6));
    const [userInput, setUserInput] = useState("");
    const [isValid, setIsValid] = useState(true);

    const regenerateCaptcha = () => {
        setCaptchaText(generateRandomString(6));
        setUserInput("");
        setIsValid(true);
    };
    const handleInputChange = (event) => {
        setUserInput(event.target.value);
        setIsValid(true);
    };


    const CheckValidation = () => {

        let Status = true,
            validdateData = {};

        if (!value.name || !value.name?.length) {
            validdateData.name = "Name is Required";
            Status = false;
        } else if (!isNaN(value.name)) {
            validdateData.name = "Name is invalid";
            Status = false;
        } else if (value.name === undefined) {
            validdateData.name = "Name is Required";
            Status = false;
        } else if (value.name === "") {
            validdateData.name = "Name is Required";
            Status = false;
        } else if (value.name.length <= 1) {
            validdateData.name = "Name must be maximum 2 letters";
            Status = false;
        }

        if (!value.mobileNumber === undefined) {
            validdateData.mobileNumber = "Mobile Number is Required";
            Status = false;
        } else if (!value.mobileNumber?.toString().length) {
            validdateData.mobileNumber = "Mobile Number is Required";
            Status = false;
        } else if (value.mobileNumber.toString().length !== 10) {
            validdateData.mobileNumber = "Mobile Number must be 10 digits";
            Status = false;
        }
        if (!value.email?.trim()?.length) {
            validdateData.email = "Email is Required";
            Status = false;
        }
        else if (value.email?.includes(" ")) {
            validdateData.email = "Email cannot include spaces";
            Status = false;
        }
        else if (value.email && isEmail(value.email)) {
            validdateData.email = "Email is Invalid";
            Status = false;
        }

        if (!value.city) {
            validdateData.city = "City is Required";
            Status = false;
        }
        if (!value.state) {
            validdateData.state = "State is Required";
            Status = false;
        }
        if (!value.linkedIN) {
            validdateData.linkedIN = "LinkedIN Url is Required";
            Status = false;
        }
        if (!value.linkedINFollowerCount) {
            validdateData.linkedINFollowerCount = "LinkedIN Follower Count is Required";
            Status = false;
        }
        if (!value.instagram) {
            validdateData.instagram = "Instagram Url is Required";
            Status = false;
        }
        if (!value.instagramFollowerCount) {
            validdateData.instagramFollowerCount = "Instagram Follower Count is Required";
            Status = false;
        }
        if (!value.facebook) {
            validdateData.facebook = "Facebook Url is Required";
            Status = false;
        }
        if (!value.facebookFollowerCount) {
            validdateData.facebookFollowerCount = "Facebook Follower Count is Required";
            Status = false;
        }
        if (!value.youTube) {
            validdateData.youTube = "YouTube Url is Required";
            Status = false;
        }
        if (!value.youTubeINFollowerCount) {
            validdateData.youTubeINFollowerCount = "YouTube Follower Count is Required";
            Status = false;
        }
        if (!value.nomineedescription) {
            validdateData.nomineedescription = "Nominee Description is Required";
            Status = false;
        }
        if (!value.nomineesworks) {
            validdateData.nomineesworks = "Nominee Works is Required";
            Status = false;
        }
        if (!value.Whatwasyourmostsuccessfuldigitalcampaignlastyear) {
            validdateData.Whatwasyourmostsuccessfuldigitalcampaignlastyear = "This is Required";
            Status = false;
        }
        if (!value.Canyougiveanexampleofacampaignwhereyouachievedgreatresultswithasmallbudget) {
            validdateData.Canyougiveanexampleofacampaignwhereyouachievedgreatresultswithasmallbudget = "This is Required";
            Status = false;
        }
        if (!value.CanyoudescribeadigitalstrategythatyieldedexceptionalROIforaclient) {
            validdateData.CanyoudescribeadigitalstrategythatyieldedexceptionalROIforaclient = "This is Required";
            Status = false;
        }
        if (!value.Inwhatwayshasyouragencycontributedtoadvancingdigitalmarketingpracticeswithinyourindustry) {
            validdateData.Inwhatwayshasyouragencycontributedtoadvancingdigitalmarketingpracticeswithinyourindustry = "This is Required";
            Status = false;
        }
        if (!value.Tellusaboutabigchallengeyoufacedinadigitalprojectandhowyousolvedit) {
            validdateData.Tellusaboutabigchallengeyoufacedinadigitalprojectandhowyousolvedit = "This is Required";
            Status = false;
        }
        if (!value.Whydoesyouragencydeservetowinthisdigitalawardandhowwouldthisrecognitionimpactyourfutureendeavours) {
            validdateData.Whydoesyouragencydeservetowinthisdigitalawardandhowwouldthisrecognitionimpactyourfutureendeavours = "This is Required";
            Status = false;
        }

        // if (!value.influencerCategory > 0) {
        //     validdateData.influencerCategory = "Please select the option";
        // }
        if (!value.bestdigitalagency > 0) {
            validdateData.bestdigitalagency = "Please select the option";
            Status = false;
        }
        if (userInput === "") {
            validdateData.userInput = "Please Enter Captcha";
            Status = false;
        }

        setValidation({ ...validation, ...validdateData });
        return Status;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        let inputvalue = {
            Name: value.name,
            MobileNumber: value.mobileNumber,
            Email: value.email,
            City: value.city,
            State: value.state,
            LinkedinURL: value.linkedIN,
            LinkedinFollowersCount: value.linkedINFollowerCount,
            InstagramURL: value.instagram,
            InstagramFollowerCount: value.instagramFollowerCount,
            FacebookURL: value.facebook,
            FacebookFollowerCount: value.facebookFollowerCount,
            YouTubeURL: value.youTube,
            YouTubeINFollowerCount: value.youTubeINFollowerCount,
            NomineeDescription: value.nomineedescription,
            Nomineesworks: value.nomineesworks,
            Whatwasyourmostsuccessfuldigitalcampaignlastyear: value.Whatwasyourmostsuccessfuldigitalcampaignlastyear,
            Canyougiveanexampleofacampaignwhereyouachievedgreatresultswithasmallbudget: value.Canyougiveanexampleofacampaignwhereyouachievedgreatresultswithasmallbudget,
            CanyoudescribeadigitalstrategythatyieldedexceptionalROIforaclient: value.CanyoudescribeadigitalstrategythatyieldedexceptionalROIforaclient,
            Inwhatwayshasyouragencycontributedtoadvancingdigitalmarketingpracticeswithinyourindustry: value.Inwhatwayshasyouragencycontributedtoadvancingdigitalmarketingpracticeswithinyourindustry,
            Tellusaboutabigchallengeyoufacedinadigitalprojectandhowyousolvedit: value.Tellusaboutabigchallengeyoufacedinadigitalprojectandhowyousolvedit,
            Whydoesyouragencydeservetowinthisdigitalawardandhowwouldthisrecognitionimpactyourfutureendeavours: value.Whydoesyouragencydeservetowinthisdigitalawardandhowwouldthisrecognitionimpactyourfutureendeavours,
            BestDigitalAgency: value.bestdigitalagency,
            Date: new Date(),
            Location: window.location.href,
        };
        let formdata = new FormData();
        for (let key in inputvalue) {
            formdata.append(key, inputvalue[key]);
        }
        if (CheckValidation()) {
            try {
                await axios
                    .post(
                        // "https://script.google.com/macros/s/AKfycbz4RLvoKulmL2b4VClzc7hymW8VgjPm9F9-qyw5JMsyQDSni8qpBiHpRuU-PkIEnVRjTQ/exec",
                        "https://script.google.com/macros/s/AKfycbw5jeGGSXzjkAB4rCWGn-paunoS67dvPWHWx3OzkuyA7V0jOrf7OZnndk4gHDwM7srUwg/exec",
                        formdata
                    )
                    .then(() => {
                        setValue({});
                        setThankYouModal(true);
                        e.target.reset();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                    });
            }
            catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        }
        else {
            setLoading(false);
        }
    };

    const setData = (e, key) => {
        setValue({ ...value, [key]: e });
        if (validation[key]) setValidation({ ...validation, [key]: false });
    };

    return (
        <div className="bg-agenda">
            <Header />
            <ThankYouModal
                open={thankYouModal}
                setOpen={() => {
                    setThankYouModal(false);
                }}
            />
            <div className="nomination-container">
                <div className="digital-summit-heading">
                    <div className="container">
                        <div className="stroke-text-container d-flex justify-content-center">
                            <h1 className="bg-text-stroke grey-text-stroke">Nomination</h1>
                            <h1 className="gradient-text-heading-yellow text-white">Submit Your Nomination</h1>
                        </div>
                    </div>
                </div>
                <div className="container nomination-form-container">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="mb-3">
                                    <div className="text-center mb-3">
                                        <h3 className="gradient-text">Contact Information</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div class="nomination-form-field">
                                                <h6 for="name" class="form-label ">Name*</h6>
                                                <input type="text" id="name" name="name" class="form-control"
                                                    placeholder="Enter Name"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "name");
                                                    }} />
                                                {validation.name && (
                                                    <span className="validationmsg small">{validation.name}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div class="nomination-form-field">
                                                <h6 for="email" class="form-label ">Email*</h6>
                                                <input type="text" id="email" name="email"
                                                    class="form-control" placeholder="Enter Email"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "email");
                                                    }} />
                                                {validation.email && (
                                                    <span className="validationmsg small">{validation.email}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div class="nomination-form-field">
                                                <h6 for="phoneNumber" class="form-label ">Phone Number*</h6>
                                                <input type="number" id="phoneNumber" name="mobileNumber"
                                                    class="form-control" placeholder="Enter Phone Number"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "mobileNumber");
                                                    }} />
                                                {validation.mobileNumber && (
                                                    <span className="validationmsg small">{validation.mobileNumber}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div class="nomination-form-field">
                                                <h6 for="city" class="form-label ">City*</h6>
                                                <input type="text" id="city" name="city"
                                                    class="form-control" placeholder="Enter City"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "city");
                                                    }} />
                                                {validation.city && (
                                                    <span className="validationmsg small">{validation.city}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div class="nomination-form-field">
                                                <h6 for="state" class="form-label ">State</h6>
                                                <input type="text" id="state" name="state"
                                                    class="form-control" placeholder="Enter State"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "state");
                                                    }} />
                                                {validation.state && (
                                                    <span className="validationmsg small">{validation.state}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="mb-4">
                                    <div className="mb-3">
                                        <h3 className="gradient-text text-center mb-3">Category for Nomination</h3>
                                    </div>
                                    <div className="row">
                                        {/* <div className="col-lg-12">
                                            <div class="nomination-form-field">
                                                <>
                                                    <h6 htmlFor="designation" className="form-label">
                                                        25+ Best Content & Influencer Categries*
                                                    </h6>
                                                    <select
                                                        onChange={(e) => {
                                                            setData(e.target.value, "influencerCategory");
                                                        }}
                                                        className="form-select form-control"
                                                    >
                                                        <option>--- Select one option ---</option>
                                                        <option value={"Food"}>Food</option>
                                                        <option value="Travel">Travel</option>
                                                        <option value="Lifestyle">Lifestyle</option>
                                                        <option value="Fitness">Fitness</option>
                                                        <option value="Health">Health</option>
                                                        <option value="Cooking">Cooking</option>
                                                        <option value="Comedy">Comedy</option>
                                                        <option value="Mindset & Psychology">Mindset & Psychology</option>
                                                        <option value="Life Coach">Life Coach</option>
                                                        <option value="Coaches (Other)">Coaches (Other)</option>
                                                        <option value="Finance">Finance</option>
                                                        <option value="Tech Educators">Tech Educators</option>
                                                        <option value="Professionals(Doctors, Lawyers & etc)">Professionals(Doctors, Lawyers & etc)</option>
                                                        <option value="Entrepreneurs & Business People">Entrepreneurs & Business People</option>
                                                        <option value="Best Storyteller">Best Storyteller</option>
                                                        <option value="The Disruptor of the year">The Disruptor of the year</option>
                                                        <option value="Most Impactful Agri Creator">Most Impactful Agri Creator</option>
                                                        <option value="Gaming">Gaming</option>
                                                        <option value="Tech Creator - Technology & Hardware">Tech Creator - Technology & Hardware</option>
                                                        <option value="Quality Producton (Tech) - Video & Edits">Quality Producton (Tech) - Video & Edits</option>
                                                        <option value="Beauty & Fashine">Beauty & Fashine</option>
                                                        <option value="Couple Influencer of the Year">Couple Influencer of the Year</option>
                                                        <option value="Positive Impact Creators / Shining Stars">Positive Impact Creators / Shining Stars</option>
                                                        <option value="Gaming">Social Service / Humanity Award</option>
                                                        <option value="Auto-Motive Influencer">Auto-Motive Influencer</option>
                                                        <option value="Entertainer of the Year">Entertainer of the Year</option>
                                                        <option value="Celebrity Creator of the year">Celebrity Creator of the year</option>
                                                        <option value="Nano Influencer of the year">Nano Influencer of the year</option>
                                                    </select>
                                                    {validation.influencerCategory && (
                                                        <span className="validationmsg small">{validation.influencerCategory}</span>
                                                    )}
                                                </>
                                            </div>
                                        </div> */}
                                        <div className="col-lg-12">
                                            <div class="nomination-form-field">
                                                <>
                                                    <h6 htmlFor="designation" className="form-label">
                                                        Best Digital Agency Awards*
                                                    </h6>
                                                    <select
                                                        onChange={(e) => {
                                                            setData(e.target.value, "bestdigitalagency");
                                                        }}
                                                        className="form-select form-control"
                                                    >
                                                        <option>--- Select one option ---</option>
                                                        <option value={"Campaign Effectiveness Award"}>Campaign Effectiveness Award</option>
                                                        <option value="Best use of AI in client Campaign">Best use of AI in client Campaign</option>
                                                        <option value="Best PR Campaign">Best PR Campaign</option>
                                                        <option value="Best PPC Campaign">Best PPC Campaign</option>
                                                        <option value="Best Social Media Campaign">Best Social Media Campaign</option>
                                                        <option value="Best Creative Campaign - Agency Award">Best Creative Campaign - Agency Award</option>
                                                        <option value="Best Digital Start-Up of the year">Best Digital Start-Up of the year</option>
                                                    </select>
                                                    {validation.bestdigitalagency && (
                                                        <span className="validationmsg small">{validation.bestdigitalagency}</span>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="mb-3">
                                    <div className="text-center mb-3">
                                        <h3 className="gradient-text mb-3">
                                            Social Media Handles / Follower Count
                                        </h3>
                                        <h6 class="na-content mb-4">
                                            ( Please type “NA” for fields/URLs not applicable to you )
                                        </h6>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div class="nomination-form-field">
                                                <h6 for="linkedIN" class="form-label ">LinkedIN* </h6>
                                                <input type="text" id="linkedIN" name="linkedIN" class="form-control"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "linkedIN");
                                                    }} placeholder="Enter Profile URLs" />
                                                {validation.linkedIN && (
                                                    <span className="validationmsg small">{validation.linkedIN}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div class="nomination-form-field">
                                                <h6 for="linkedINFollowerCount" class="form-label ">LinkedIN / Follower Count*</h6>
                                                <input type="text" id="linkedINFollowerCount"
                                                    name="linkedINFollowerCount" class="form-control"
                                                    placeholder="Enter Count"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "linkedINFollowerCount");
                                                    }} />
                                                {validation.linkedINFollowerCount && (
                                                    <span className="validationmsg small">{validation.linkedINFollowerCount}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div class="nomination-form-field">
                                                <h6 for="Instagram" class="form-label ">Instagram*</h6>
                                                <input type="text" id="Instagram" name="instagram"
                                                    class="form-control" placeholder="Enter Profile URLs"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "instagram");
                                                    }} />
                                                {validation.instagram && (
                                                    <span className="validationmsg small">{validation.instagram}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div class="nomination-form-field">
                                                <h6 for="InstagramINFollowerCount" class="form-label ">Instagram / Follower Count*</h6>
                                                <input type="text" id="InstagramFollowerCount"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "instagramFollowerCount");
                                                    }}
                                                    name="instagramFollowerCount" class="form-control" placeholder="Enter Count" />
                                                {validation.instagramFollowerCount && (
                                                    <span className="validationmsg small">{validation.instagramFollowerCount}</span>
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-8">
                                            <div class="nomination-form-field">
                                                <h6 for="X" class="form-label ">X*</h6>
                                                <input type="text" id="X" class="form-control"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "x");
                                                    }} name="x" placeholder="Enter Profile URLs" />
                                                {validation.x && (
                                                    <span className="validationmsg small">{validation.x}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div class="nomination-form-field">
                                                <h6 for="XFollowerCount" class="form-label ">X / Follower Count*</h6>
                                                <input type="text" id="xFollowerCount"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "xFollowerCount");
                                                    }}
                                                    name="xFollowerCount" class="form-control" placeholder="Enter Count" />
                                                {validation.xFollowerCount && (
                                                    <span className="validationmsg small">{validation.xFollowerCount}</span>
                                                )}
                                            </div>
                                        </div> */}
                                        <div className="col-lg-8">
                                            <div class="nomination-form-field">
                                                <h6 for="Facebook" class="form-label ">Facebook*</h6>
                                                <input type="text" id="Facebook" name="facebook"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "facebook");
                                                    }}
                                                    class="form-control" placeholder="Enter Profile URLs" />
                                                {validation.facebook && (
                                                    <span className="validationmsg small">{validation.facebook}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div class="nomination-form-field">
                                                <h6 for="FacebookFollowerCount" class="form-label ">Facebook / Follower Count*</h6>
                                                <input type="text" id="FacebookFollowerCount"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "facebookFollowerCount");
                                                    }}
                                                    name="facebookFollowerCount" class="form-control" placeholder="Enter Count" />
                                                {validation.facebookFollowerCount && (
                                                    <span className="validationmsg small">{validation.facebookFollowerCount}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <div class="nomination-form-field">
                                                <h6 for="YouTube" class="form-label ">You Tube* </h6>
                                                <input type="text" id="YouTube" name="youTube"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "youTube");
                                                    }}
                                                    class="form-control" placeholder="Enter Profile URLs" />
                                                {validation.youTube && (
                                                    <span className="validationmsg small">{validation.youTube}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div class="nomination-form-field">
                                                <h6 for="YouTubeFollowerCount" class="form-label ">You Tube / Follower Count*</h6>
                                                <input type="text" id="YouTubeINFollowerCount"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "youTubeINFollowerCount");
                                                    }}
                                                    name="youTubeINFollowerCount" class="form-control" placeholder="Enter Count" />
                                                {validation.youTubeINFollowerCount && (
                                                    <span className="validationmsg small">{validation.youTubeINFollowerCount}</span>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="mb-3">
                                    <div className="text-center mb-3">
                                        <h3 className="gradient-text">Short Bio</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div class="nomination-form-field">
                                                <h6 for="nominee" class="form-label ">A brief description of the nominee*</h6>
                                                <textarea class="form-control" name="nomineedescription"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "nomineedescription");
                                                    }} placeholder="Enter Short Bio" id="nominee" rows="3"></textarea>
                                                {validation.nomineedescription && (
                                                    <span className="validationmsg small">{validation.nomineedescription}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="mb-3">
                                    <div className="text-center mb-3">
                                        <h3 className="gradient-text">Portfolio or Content Samples</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div class="nomination-form-field">
                                                <h6 for="nominee’sworks" class="form-label "> My Portfolio*</h6>
                                                <input type="text" id="nominee’sworks"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "nomineesworks");
                                                    }}
                                                    name="nomineesworks" class="form-control" placeholder="Enter Portfolio Links" />
                                                {validation.nomineesworks && (
                                                    <span className="validationmsg small">{validation.nomineesworks}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="mb-5">
                                    <div className="text-center mb-4">
                                        <h3 className="gradient-text">Answer the below Questions</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div class="nomination-form-field">
                                                <h6 class="form-label ">What was your most successful digital campaign last year, and why?*</h6>
                                                <input type="text" class="form-control"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "Whatwasyourmostsuccessfuldigitalcampaignlastyear");
                                                    }}
                                                    name="Whatwasyourmostsuccessfuldigitalcampaignlastyear" placeholder="Enter Answer" />
                                                {validation.Whatwasyourmostsuccessfuldigitalcampaignlastyear && (
                                                    <span className="validationmsg small">{validation.Whatwasyourmostsuccessfuldigitalcampaignlastyear}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div class="nomination-form-field">
                                                <h6 class="form-label ">Can you give an example of a campaign where you achieved great results with a small budget*</h6>
                                                <input type="text" class="form-control"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "Canyougiveanexampleofacampaignwhereyouachievedgreatresultswithasmallbudget");
                                                    }}
                                                    name="Canyougiveanexampleofacampaignwhereyouachievedgreatresultswithasmallbudget" placeholder="Enter Answer" />
                                                {validation.Canyougiveanexampleofacampaignwhereyouachievedgreatresultswithasmallbudget && (
                                                    <span className="validationmsg small">{validation.Canyougiveanexampleofacampaignwhereyouachievedgreatresultswithasmallbudget}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div class="nomination-form-field">
                                                <h6 class="form-label ">Can you describe a digital strategy that yielded exceptional ROI for a client? How did you measure its success?*</h6>
                                                <input type="text" class="form-control" name="CanyoudescribeadigitalstrategythatyieldedexceptionalROIforaclient"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "CanyoudescribeadigitalstrategythatyieldedexceptionalROIforaclient");
                                                    }}
                                                    placeholder="Enter Answer" />
                                                {validation.CanyoudescribeadigitalstrategythatyieldedexceptionalROIforaclient && (
                                                    <span className="validationmsg small">{validation.CanyoudescribeadigitalstrategythatyieldedexceptionalROIforaclient}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div class="nomination-form-field">
                                                <h6 class="form-label ">In what ways has your agency contributed to advancing digital marketing practices within your industry?*</h6>
                                                <input type="text" class="form-control" name="Inwhatwayshasyouragencycontributedtoadvancingdigitalmarketingpracticeswithinyourindustry"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "Inwhatwayshasyouragencycontributedtoadvancingdigitalmarketingpracticeswithinyourindustry");
                                                    }}
                                                    placeholder="Enter Answer" />
                                                {validation.Inwhatwayshasyouragencycontributedtoadvancingdigitalmarketingpracticeswithinyourindustry && (
                                                    <span className="validationmsg small">{validation.Inwhatwayshasyouragencycontributedtoadvancingdigitalmarketingpracticeswithinyourindustry}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div class="nomination-form-field">
                                                <h6 class="form-label ">Tell us about a big challenge you faced in a digital project and how you solved it?*</h6>
                                                <input type="text" class="form-control" name="Tellusaboutabigchallengeyoufacedinadigitalprojectandhowyousolvedit"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "Tellusaboutabigchallengeyoufacedinadigitalprojectandhowyousolvedit");
                                                    }} placeholder="Enter Answer" />
                                                {validation.Tellusaboutabigchallengeyoufacedinadigitalprojectandhowyousolvedit && (
                                                    <span className="validationmsg small">{validation.Tellusaboutabigchallengeyoufacedinadigitalprojectandhowyousolvedit}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div class="nomination-form-field">
                                                <h6 class="form-label ">Why does your agency deserve to win this digital award, and how would this recognition impact your future endeavours?*</h6>
                                                <input type="text" class="form-control"
                                                    onChange={(e) => {
                                                        setData(e.target.value, "Whydoesyouragencydeservetowinthisdigitalawardandhowwouldthisrecognitionimpactyourfutureendeavours");
                                                    }}
                                                    name="Whydoesyouragencydeservetowinthisdigitalawardandhowwouldthisrecognitionimpactyourfutureendeavours" placeholder="Enter Answer" />
                                                {validation.Whydoesyouragencydeservetowinthisdigitalawardandhowwouldthisrecognitionimpactyourfutureendeavours && (
                                                    <span className="validationmsg small">{validation.Whydoesyouragencydeservetowinthisdigitalawardandhowwouldthisrecognitionimpactyourfutureendeavours}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div class="nomination-form-field">
                                                <div className="row">

                                                    <div className="col-lg-10 col-md-8 col-sm-8 col-7">

                                                        <div className="inputimg form-control">
                                                            <img className="icon-key" src={key} alt="User" />
                                                            <input
                                                                type="text"
                                                                className="input-field-text"
                                                                placeholder="Secutiry Text"
                                                                value={userInput}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>


                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-4 col-5 p-0">
                                                        <div className="captchabox container">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-8 p-0">
                                                                    <span className="captchasize unselectable">
                                                                        {captchaText}
                                                                    </span>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                                                    <span
                                                                        className="material-symbols-outlined captchbutton"
                                                                        onClick={regenerateCaptcha}
                                                                    >
                                                                        cached
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {validation.userInput && (
                                                    <span className="validationmsg small">{validation.userInput}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="nomination-btn text-center">
                                                <button class={`btn rounded font-bold yellow-btn w-25   ${loading ? 'sending' : ''} `}>
                                                    {loading ? (
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only ">Loading...</span>
                                                        </div>
                                                    ) : (
                                                        "Submit"
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>


                </div>
            </div>
            <Footer />
        </div>
    );
};

export default NominationAgencyAward;
