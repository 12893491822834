import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import PartnerWithUsContent from "../Components/PartnerWithUs/PartnerWithUsContent";

const PartnerWithUs = () => {
    const location = useLocation();
    useEffect(() => {
      const { search } = location;
      if (!search) {
        window.scroll(0, 0);
      }
    }, []);
    
    return (
        <div >
            <Header />
            <PartnerWithUsContent />
            <Footer />
        </div>
    );
};

export default PartnerWithUs;
