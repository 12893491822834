import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowWhite from "../../assets/images/arrow-white.webp"
import ArrowGold from "../../assets/images/arrow-gold.webp"
import ArrowBlack from "../../assets/images/arrow-black.webp"
import BGAWARD from "../../assets/images/bg-award-eagle.webp"
const AwardsBanner = () => {
    const navigation = useNavigate();
    return (
        <div className="">
            <div className="awards-banner">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="home-banner-content text-center">
                                <h1 className="banner-white-text fs-partner mb-0">AWARDS</h1>
                                <h1 className="textAnimationContent fs-partner ">NIGHT</h1>
                                <div className="nomination-btn d-flex justify-content-center gap-5 text-center">
                                    <NavLink to="/Awards?id=InfluencerAward" className="btn text-decoration-none rounded font-bold yellow-btn">
                                        <span className="small font-bold">Nominations has been closed For Influencer Award</span> {' '}
                                        <div className="figure">
                                            <img className="ticket-gold" src={ArrowBlack} alt="" />
                                            <img className="ticket-white" src={ArrowGold} alt="" />
                                        </div>
                                    </NavLink>
                                    <NavLink to="/Awards?id=AgencyAward" className="btn rounded text-decoration-none font-bold yellow-btn">
                                        <span className="small font-bold">Nominations has been closed For Agency Award</span> {' '}
                                        <div className="figure">
                                            <img className="ticket-gold" src={ArrowBlack} alt="" />
                                            <img className="ticket-white" src={ArrowGold} alt="" />
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="position-relative">
                    <div className="">
                        <img className="bg-award-eagle" src={BGAWARD} alt="" />
                    </div>
                    <div className="ab-eagle"><h1 className="attend-card-text-white">25+ BEST CONTENT & INFLUENCER CATEGORIES</h1></div>
                </div>
            </div>
        </div>
    );
};

export default AwardsBanner;
