import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import ThankYouModal from "./ThankyouModal";
import CustomModal from "./CustomModal";
import { useNavigate } from "react-router-dom";
import SoldOutImg from "../../assets/images/sold_out_img_2.webp"
const BuyTicketModal = ({ open, setOpen }) => {
    const navigation = useNavigate();
    const [value, setValue] = useState({});
    const [validation, setValidation] = useState({});
    const [thankYouModal, setThankYouModal] = useState(false);
    const [loading, setLoading] = useState(false);

    function isEmail(email) {
        return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    }
    const CheckValidation = () => {

        let Status = true,
            validdateData = {};
        if (!value.name || !value.name?.length) {
            validdateData.name = "Name is Required";
            Status = false;
        } else if (!isNaN(value.name)) {
            validdateData.name = "Name is invalid";
            Status = false;
        } else if (value.name === undefined) {
            validdateData.name = "Name is Required";
            Status = false;
        } else if (value.name === "") {
            validdateData.name = "Name is Required";
            Status = false;
        } else if (value.name.length <= 1) {
            validdateData.name = "Name must be maximum 2 letters";
            Status = false;
        }

        if (!value.mobileNumber === undefined) {
            validdateData.mobileNumber = "Mobile Number is Required";
            Status = false;
        } else if (!value.mobileNumber?.toString().length) {
            validdateData.mobileNumber = "Mobile Number is Required";
            Status = false;
        } else if (value.mobileNumber.toString().length !== 10) {
            validdateData.mobileNumber = "Mobile Number must be 10 digits";
            Status = false;
        }

        if (!value.email?.trim()?.length) {
            validdateData.email = "Email is Required";
            Status = false;
        }
        else if (value.email?.includes(" ")) {
            validdateData.email = "Email cannot include spaces";
            Status = false;
        }
        else if (value.email && isEmail(value.email)) {
            validdateData.email = "Email is Invalid";
            Status = false;
        }

        if (!value.city) {
            validdateData.city = "City is Required";
            Status = false;
        }
        setValidation({ ...validation, ...validdateData });
        return Status;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // console.log(validation);
        let inputvalue = {
            Name: value.name,
            MobileNumber: value.mobileNumber,
            Email: value.email,
            City: value.city,
            Date: new Date(),
            Location: window.location.href,
        };
        let formdata = new FormData();
        for (let key in inputvalue) {
            formdata.append(key, inputvalue[key]);
        }
        if (CheckValidation()) {
            try {
                await axios
                    .post(
                        // "https://script.google.com/macros/s/AKfycbyePWs3LzmtwXblM_eDab5PJbxH7QCa78fFTJzCR67GKB-lK2cXUM5bkBoOEE2ef_7N/exec",
                        "https://script.google.com/macros/s/AKfycby6A0M1d4JCsaOfoC12yXmYK1Om5IvS2MHv80pSNAG1QoffIVAgueIU6XyScD_N-M_I/exec",
                        formdata
                    )
                    .then(() => {
                        setValue({});
                        // setTimeout(() => {
                        //     setLoading(false)
                        //     setOpen(false)
                        //     navigation("/BuyTicket");
                        // }, 1000);
                        setOpen(false);
                        navigation("/BuyTicket");
                        e.target.reset();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                    });
            }
            catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        }
        else {
            setLoading(false);
        }
    };

    const setData = (e, key) => {
        setValue({ ...value, [key]: e });
        if (validation[key]) setValidation({ ...validation, [key]: false });
    };

    return (
        <div >
            <ThankYouModal
                open={thankYouModal}
                setOpen={() => {
                    setThankYouModal(false);
                }}
            />
            <div className="buyticket-modal-content">
                <CustomModal
                    open={open}
                    onClickOutside={() => {
                        setOpen(false);
                    }}
                >
                    <div className="modal-content">
                        <div className="close-btn pt-2">
                            <i
                                className="fa-regular fa-circle-xmark"
                                onClick={() => setOpen(false)}
                            ></i>
                        </div>
                        <div className="">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 m-auto">
                                        <div className="text-center">
                                            <img className="sold-out-modal-img" src={SoldOutImg} alt="sold-out" />
                                        </div>
                                        <h2 className="gradient-text text-dark text-center mt-3 mb-5">Tickets are SOLD OUT</h2>
                                    </div>
                                    {/* <div className="col-lg-10">
                                        <h5 className="gradient-text text-dark text-center mt-4 mb-3">What's The Best Contact Info To Reserve Your Ticket Under?</h5>
                                    </div> */}
                                    {/* <div className="col-lg-10">
                                        <form className="" onSubmit={(e) => handleSubmit(e)}>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-12">
                                                    <div className="mb-3">
                                                        <input type="text" className="form-control pws-form"
                                                            placeholder="Name*" name="name"
                                                            onChange={(e) => {
                                                                setData(e.target.value, "name");
                                                            }} />
                                                        {validation.name && (
                                                            <span className="validationmsg small">{validation.name}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="mb-3">
                                                        <input type="number" className="form-control pws-form"
                                                            name="mobileNumber"
                                                            onChange={(e) => {
                                                                setData(e.target.value, "mobileNumber");
                                                            }}
                                                            placeholder="Mobile Number*" />
                                                        {validation.mobileNumber && (
                                                            <span className="validationmsg small">{validation.mobileNumber}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="mb-3">
                                                        <input type="text" className="form-control pws-form"
                                                            name="email"
                                                            onChange={(e) => {
                                                                setData(e.target.value, "email");
                                                            }}
                                                            placeholder="Email*" />
                                                        {validation.email && (
                                                            <span className="validationmsg small">{validation.email}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="mb-3">
                                                        <input type="text" className="form-control pws-form"
                                                            name="city"
                                                            onChange={(e) => {
                                                                setData(e.target.value, "city");
                                                            }}
                                                            placeholder="City*" />
                                                        {validation.city && (
                                                            <span className="validationmsg small">{validation.city}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 mb-4 ">
                                                    <div className="bg-dark btn-contact">
                                                        <button className={`btn btn-anim-contact font-bold w-100 py-2.5  ${loading ? 'sending' : ''}`} type="submit" >
                                                            {loading ? (
                                                                <div className="spinner-border" role="status">
                                                                    <span className="sr-only ">Loading...</span>
                                                                </div>
                                                            ) : (
                                                                "Submit"
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomModal>
            </div>
        </div>
    );
};

export default BuyTicketModal;
