import React, { useState, useEffect } from "react";
import Calender from "../../assets/images/calendar.webp";
import Location from "../../assets/images/map.webp";
import { BookTickets } from "../Carousel/BookTickets";
import { useNavigate } from "react-router-dom";
import TicketWhite from "../../assets/images/ticket-white.webp";
import TicketBlack from "../../assets/images/ticket-black.webp";
import TicketGold from "../../assets/images/ticket-gold.webp";
import BuyTicketModal from "../Modal/BuyTicketModal";
const AgendaBanner = () => {
    const navigation = useNavigate();
    const [buyTicketModal, setBuyTicketModal] = useState(false);
    return (
        <div>
            <BuyTicketModal
                open={buyTicketModal}
                setOpen={() => {
                    setBuyTicketModal(false);
                }}
            />
            <div className="agenda-banner">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="home-banner-content text-center">
                                <h1 className="banner-white-text fs-partner mb-4">AGENDA</h1>
                                <div className="mb-4">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-3">
                                            <div className="flex-align-center justify-content-center">
                                                <img
                                                    className="calender-icon"
                                                    src={Calender}
                                                    alt=""
                                                />
                                                <h5 className="date-text text-white mb-0">25-26 May, 2024</h5>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 my-auto">
                                            <div className="flex-align-center justify-content-center">
                                                <h5 className="date-text text-white d-lg-block d-none mb-0">|</h5>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="flex-align-center justify-content-center">
                                                <img
                                                    className="calender-icon"
                                                    src={Location}
                                                    alt=""
                                                />
                                                <h5 className="date-text text-white mb-0">Hilton, Chennai</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button 
                                // onClick={() => setBuyTicketModal(true)} 
                                onClick={() => navigation('/BuyTicket')}
                                class="btn yellow-btn" data="Buy Ticket">
                                    <div class="figure">
                                        <img className="ticket-gold" src={TicketBlack} alt="" />
                                        <img className="ticket-white" src={TicketGold} alt="" />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-5">
                <BookTickets />
            </div>
        </div>
    );
};

export default AgendaBanner;
