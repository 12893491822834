import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import BuyTicketContent from "../Components/BuyTicket/BuyTicketContent";

const BuyTicket = () => {
  const location = useLocation();
  useEffect(() => {
    const { search } = location;
    if (!search) {
      window.scroll(0, 0);
    }
  }, []);

  //counter time start

  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Target date: 25 May 2024 00:00:00
    const targetDate = new Date(2024, 4, 15); // Note: Months are zero-based, so 4 is May

    const interval = setInterval(() => {
      const currentDate = new Date();
      const difference = targetDate.getTime() - currentDate.getTime();

      if (difference <= 0) {
        clearInterval(interval);
        setTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        setTime({ days, hours, minutes, seconds });
      }
    }, 10);

    return () => clearInterval(interval);
  }, []);

  const { days, hours, minutes, seconds } = time;

  //counter time end
  return (
    <div className="header-banner-contain buy-ticket-page" >
      {/* <div className="day-count-section">
        <div className="container-fluid">
          {days === 0 && hours === 0 && minutes === 0 && seconds === 0 ? (
            <h1 className="textAnimationContent">Early Bird Tickets Expired</h1>
          ) : (
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="row pt-2 pt-lg-0">
                  <div className="col-lg-3 col-12 my-auto">
                    <div className="flex-align-center">
                      <h5 className="text-early-bird fs-5 mb-0">Early Bird Tickets Expires In</h5>
                    </div>
                  </div>
                  <div className="col-lg-2 col-3">
                    <div className="flex-align-center">
                      <div className="single-box gap-2">
                        <h1 className="gradient-text">{days}</h1>
                        <h5 className="counter-text">Days</h5>
                      </div>
                      <h5 className="counter-text count-colun">:</h5>
                    </div>
                  </div>
                  <div className="col-lg-2  col-3">
                    <div className="flex-align-center">
                      <div className="single-box gap-2">
                        <h1 className="gradient-text">{hours}</h1>
                        <h5 className="counter-text">Hours</h5>
                      </div>
                      <h5 className="counter-text count-colun">:</h5>
                    </div>
                  </div>
                  <div className="col-lg-2  col-3">
                    <div className="flex-align-center">
                      <div className="single-box gap-2">
                        <h1 className="gradient-text">{minutes}</h1>
                        <h5 className="counter-text">Minutes</h5>
                      </div>
                      <h5 className="counter-text count-colun">:</h5>
                    </div>
                  </div>
                  <div className="col-lg-2  col-3">
                    <div className="flex-align-center">
                      <div className="single-box gap-2">
                        <h1 className="gradient-text">{seconds}</h1>
                        <h5 className="counter-text">Seconds</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div> */}
      <BuyTicketContent />
      <Footer />
    </div>
  );
};

export default BuyTicket;
