import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.webp";
import TicketGold from '../../assets/images/ticket-gold.webp';
import TicketWhite from '../../assets/images/ticket-white.webp';
import TicketBlack from '../../assets/images/ticket-black.webp';
import BuyTicketModal from "../Modal/BuyTicketModal";
const Header = () => {
  const navigation = useNavigate();
  const [buyTicketModal, setBuyTicketModal] = useState(false);
  return (
    <div>
      <BuyTicketModal
        open={buyTicketModal}
        setOpen={() => {
          setBuyTicketModal(false);
        }}
      />
      <div className="static-header">
        <nav className="navbar navbar-expand-lg navbar-dark container">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img className="header-logo" src={Logo} alt="Logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item my-auto">
                  <NavLink className="nav-link" aria-current="page" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item  my-auto">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="/Agenda"
                  >
                    Agenda
                  </NavLink>
                </li>
                <li className="nav-item  my-auto">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="/Awards"
                  >
                    Awards
                  </NavLink>
                </li>
                <li className="nav-item  my-auto">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="/PartnerWithUs"
                  >
                    Partner With Us
                  </NavLink>
                </li>
                <div className="nav-btn-container">
                  <button 
                  // onClick={() => setBuyTicketModal(true)} 
                  onClick={() => navigation('/BuyTicket')}
                  class="btn nav-btn" data="Buy Ticket">
                    <div class="figure">
                      <img className="ticket-gold" src={TicketGold} alt="" />
                      <img className="ticket-white" src={TicketBlack} alt="" />
                    </div>
                  </button>
                </div>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
