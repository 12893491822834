import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import BT1 from "../../assets/images/h1.webp";
import BT2 from "../../assets/images/h2.webp";

export const Hilton = () => {
    return (
        <div className="bg-gradient-container bk-ticket">
            <div className="container-fluid scroll-rotate-overflow-carousel">
                <div className=" our-clients-container">
                    <OwlCarousel
                        className="owl-theme"
                        loop
                        autoplay={true}
                        items="1"
                        dots={false}
                        smartSpeed={2000}
                        nav={false}
                        margin={20}
                        center={true}
                        autoplayTimeout={2000}
                        responsive={{
                            360: {
                                items: "2",
                            },
                            414: {
                                items: "1",
                            },
                            670: {
                                items: "2",
                            },
                            992: {
                                items: "5",
                            },
                            1200: {
                                items: "6",
                            },
                        }}
                    >
                        <div>
                            <img className="client-img" src={BT1} alt=""></img>
                        </div>
                        <div>
                            <img className="client-img" src={BT2} alt=""></img>
                        </div>
                        <div>
                            <img className="client-img" src={BT1} alt=""></img>
                        </div>
                        <div>
                            <img className="client-img" src={BT2} alt=""></img>
                        </div>
                    </OwlCarousel>
                </div>
                <div className="our-clients-container-bg">
                </div>
            </div>
        </div>
    );
};
