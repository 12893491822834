import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import AgendaContent from "../Components/Agenda/AgendaContent";
import AgendaBanner from "../Components/Agenda/AgendaBanner";

const Agenda = () => {
    const location = useLocation();
    useEffect(() => {
      const { search } = location;
      if (!search) {
        window.scroll(0, 0);
      }
    }, []);
    return (
        <div className="bg-agenda">
            <Header />
            <AgendaBanner/>
            <AgendaContent/>
            <Footer />
        </div>
    );
};

export default Agenda;
