import React from 'react'
import FooterLogo from '../../assets/images/footer-logo.webp';
import FooterSELogo from '../../assets/images/social-eagle.webp';
import { Link } from 'react-router-dom';
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div >
      {/* <a href="https://api.whatsapp.com/send?phone=+91 8428421222&text=Hi%21%20TNDigitalSummit." class="float" target="_blank">
        <i class="fa fa-whatsapp my-float"></i>
      </a> */}
      <a href="https://api.whatsapp.com/send?phone=%2B918428421222&amp;text=Hi%21%20TNDigitalSummitTeam." class="float">
        <i class="fa fa-whatsapp my-float"></i>
      </a>
      <div className='container py-5'>
        <div className='footer-container'>
          <div className='footer-section py-5'>
            <div className='row'>
              <div className='col-lg-3 my-auto'>
                <div>
                  <Link to="/" ><img className='footer-logo' src={FooterLogo} alt='' /></Link>
                  <div className='footer-icons d-flex items-center'>
                    <h5 className='footer-heading mt-1 mb-0'>Follow Us :</h5>
                    <a href="https://www.instagram.com/tndigitalsummit/?igsh=dnRsaGgxZ2RxajVk" target='_blank'><span className='instagram px-2 py-0 mb-0'><i class="fa-brands fa-instagram"></i></span></a>
                    {/* <a href="" target='_blank'><span className='linkedin'><i class="fa-brands fa-linkedin"></i></span></a>
                    <a href="" target='_blank'><span className='twitter'><i class="fa-brands fa-x-twitter"></i></span></a> */}
                  </div>
                </div>
              </div>
              <div className='col-lg-2 my-auto'>
                <div className='footer-links'>
                  <h5 className='footer-heading'>Quick Links</h5>
                  <div>
                    <ul className='ps-0'>
                      <li><Link className='footer-link-text' to={"/"} onClick={scrollToTop}>Home</Link></li>
                      <li><Link className='footer-link-text' to={"/Agenda"} onClick={scrollToTop}>Agenda</Link></li>
                      <li><Link className='footer-link-text' to={"/Awards"} onClick={scrollToTop}>Awards</Link></li>
                      <li><Link className='footer-link-text' to={"/PartnerWithUs"} onClick={scrollToTop}>Partner With Us</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='footer-links'>
                  <h5 className='footer-heading'>Contact Us</h5>
                  <div>
                    <ul className='ps-0'>
                      <li className='footer-contact'><a className='footer-link-text' target='_blank' href="tel:+918428421222"><span><i class="fa-solid fa-phone"></i></span> +91 8428421222</a></li>
                      <li className='footer-contact'><a className='footer-link-text' target='_blank' href="mailto:flyhigh@socialeagle.in"><span><i class="fa-solid fa-envelope"></i></span> flyhigh@socialeagle.in</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 my-auto'>
                <div>
                  <img className='footer-se-logo' src={FooterSELogo} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
