import React from "react";
import { useState } from "react";
import { OurClients } from "../Carousel/OurClients";
import Sign1 from "../../assets/images/img-1.webp";
import Sign2 from "../../assets/images/img-2.webp";
import Sign3 from "../../assets/images/img-3.webp";
import Sign4 from "../../assets/images/img-4.webp";
import Sign5 from "../../assets/images/img-5.webp";
import TNDSBG from "../../assets/images/bg-tnds.webp";
import TNDSBGBottom from "../../assets/images/tn-bg-bottom.webp";
import Card1 from "../../assets/images/card1.webp";
import Card2 from "../../assets/images/card2.webp";
import Card3 from "../../assets/images/card3.webp";
import Card4 from "../../assets/images/card4.webp";
import Card5 from "../../assets/images/card5.webp";
import Card6 from "../../assets/images/card6.webp";

import E1 from "../../assets/images/e1.webp";
import E2 from "../../assets/images/e2.webp";
import E3 from "../../assets/images/e3.webp";
import E4 from "../../assets/images/e4.webp";
import E5 from "../../assets/images/e5.webp";
import E6 from "../../assets/images/e6.webp";

import WCA1 from "../../assets/images/wca1.webp";
import WCA2 from "../../assets/images/wca2.webp";
import WCA3 from "../../assets/images/wca3.webp";
import WCA4 from "../../assets/images/wca4.webp";
import WCA5 from "../../assets/images/wca5.webp";
import WCA6 from "../../assets/images/wca6.webp";

import WhyAttendCard1 from "../../assets/images/why-attend-card-1.webp";
import WhyAttendCard2 from "../../assets/images/why-attend-card-2.webp";
import WhyAttendCard3 from "../../assets/images/why-attend-card-3.webp";
import WhyAttendCard4 from "../../assets/images/why-attend-card-4.webp";
import WhyAttendCard5 from "../../assets/images/why-attend-card-5.webp";
import WhyAttendCard6 from "../../assets/images/why-attend-card-6.webp";

import HiltonImg from "../../assets/images/hilton.webp";

import InflunceImg from "../../assets/images/influence-img.webp";

import { BookTickets } from "../Carousel/BookTickets";
import { SpeakersContent } from "./SpeakersContent";
import { Hilton } from "../Carousel/Hilton";

import TicketWhite from "../../assets/images/ticket-white.webp";
import TicketBlack from "../../assets/images/ticket-black.webp";
import TicketGold from "../../assets/images/ticket-gold.webp";
import { useNavigate } from "react-router-dom";
import BuyTicketModal from "../Modal/BuyTicketModal";

const HomeContent = () => {
  const navigation = useNavigate();

  const [buyTicketModal, setBuyTicketModal] = useState(false);

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const [isHovering2, setIsHovering2] = useState(false);

  const handleMouseOver2 = () => {
    setIsHovering2(true);
  };

  const handleMouseOut2 = () => {
    setIsHovering2(false);
  };

  const [isHovering3, setIsHovering3] = useState(false);

  const handleMouseOver3 = () => {
    setIsHovering3(true);
  };

  const handleMouseOut3 = () => {
    setIsHovering3(false);
  };
  const [isHovering4, setIsHovering4] = useState(false);

  const handleMouseOver4 = () => {
    setIsHovering4(true);
  };

  const handleMouseOut4 = () => {
    setIsHovering4(false);
  };
  const [isHovering5, setIsHovering5] = useState(false);

  const handleMouseOver5 = () => {
    setIsHovering5(true);
  };

  const handleMouseOut5 = () => {
    setIsHovering5(false);
  };
  return (
    <div className="home-content-bottom">
      <BuyTicketModal
        open={buyTicketModal}
        setOpen={() => {
          setBuyTicketModal(false);
        }}
      />
      <div className="hm-sec">
        <OurClients />
      </div>
      <div className="tnds-section my-5">
        <div className="container ">
          <div className="row">
            <div className="col-lg-5 my-auto">
              <div className="tnds-bg-img">
                <img className="tnds-img" src={TNDSBG} alt=""></img>
                <div className="tnds-text-right">
                  <h1 className="gradient-text-tnds mb-0">TNDS</h1>
                  <h1 className="gradient-text-tnds mb-0">2024</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-7 my-auto">
              <div className="">
                <div className="stroke-text-container">
                  <h1 className="bg-text-stroke">TNDS</h1>
                  <h1 className="gradient-text-heading">What is TNDS?</h1>
                </div>
                <p className="para-content">
                  The TN Digital Summit is a stellar event going to be held in Tamil Nadu, India, aimed at bringing together top-notch professionals, subject matter experts, and blooming enthusiasts from the digital industry. The TN Digital Summit is organized by Social Eagle, a pioneering agency in the field of Digital Marketing and Growth. With their expertise and experience in the digital space, attendees can expect well-curated and impactful line of events that delivers immensely valuable insights, innumerable networking opportunities, and actionable tested strategies for success.
                </p>
                <p className="para-content">
                  The summit will serve as a platform for discussing the latest trends, upcoming innovations, and existing challenges within the digital space, with a focus on empowering businesses and individuals to leverage digital technologies for growth and success. It will be a one stop spot for you to learn from the pioneers and rulers of the industry .The TN Digital Summit's status as the biggest event in the digital space underscores its importance as a premier gathering for all stakeholders invested in the future of digital transformation.
                </p>
                <div className="center-mob-btn text-center mt-5">
                  <button
                    // onClick={() => setBuyTicketModal(true)}
                    onClick={() => navigation('/BuyTicket')}
                    class="btn black-btn"
                    data="Buy Ticket"
                  >
                    <div class="figure">
                      <img className="ticket-gold" src={TicketWhite} alt="" />
                      <img className="ticket-white" src={TicketGold} alt="" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* heading start */}
        <div className="significance-section mt-5">
          <div className="container ">
            <div className="stroke-text-container d-flex justify-content-center">
              <h1 className="bg-text-stroke">SIGNIFICANCE</h1>
              <h1 className="gradient-text-heading">The Significance</h1>
            </div>
          </div>
        </div>
        {/* heading end */}
        <div className="signicance-section ">
          <div className="container-fluid ">
            <div className="row justify-content-end">
              <div className="col-lg-5">
                <img className="sign-img sign-right" src={Sign1} alt=""></img>
              </div>
            </div>
          </div>
          <div
            className={`significance-bg  ${isHovering ? "show" : ""}`}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <div className="container-fluid scroll-rotate-overflow">
              <div className=" our-clients-container2">
                <div className={`container py-5 container-significance  `}>
                  <div className="row justify-content-center one-deg align-items-center">
                    {isHovering ? (
                      <>
                        <div className=" col-lg-3 my-auto">
                          <h2 className="significance-heading-stroke">
                            Scope and Diversity
                          </h2>
                        </div>
                        <div className=" col-lg-6 my-auto">
                          <p className="para-content-white">
                            The summit covers a broad range of topics
                            encompassing digital marketing, digital sales, tech
                            tools, e-commerce, entrepreneurship, content
                            creation, and more. Its comprehensive agenda ensures
                            that it appeals to a diverse audience with varied
                            interests and expertise within the digital space.
                          </p>
                        </div>
                      </>
                    ) : (
                      <div className="text-center col-lg-4 my-auto">
                        <h2 className="significance-heading">
                          Scope and <br /> Diversity
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* yello card */}
        <div className="signicance-section ">
          <div className="container-fluid">
            <div className="row justify-content-start">
              <div className="col-lg-5">
                <img className="sign-img sign-left" src={Sign2} alt=""></img>
              </div>
            </div>
          </div>
          <div
            className={`significance-bg-black  ${isHovering2 ? "show" : ""}`}
            onMouseOver={handleMouseOver2}
            onMouseOut={handleMouseOut2}
          >
            <div className="container-fluid scroll-rotate-overflow">
              <div className="our-clients-container3">
                <div className={`container py-5 container-significance  `}>
                  <div className="row justify-content-center three-deg align-items-center">
                    {isHovering2 ? (
                      <>
                        <div className=" col-lg-6 my-auto">
                          <p className="para-content-white text-dark ">
                            The summit attracts top-tier speakers, thought
                            leaders, and industry experts who share their
                            insights, experiences, and strategies for success.
                          </p>
                        </div>
                        <div className=" col-lg-3 my-auto">
                          <h2 className="sign-head-stroke-black">
                            High-profile Speakers and Participants
                          </h2>
                        </div>
                      </>
                    ) : (
                      <div className="text-center col-lg-4 my-auto">
                        <h2 className="significance-heading  text-dark">
                          High-profile <br /> Speakers and <br /> Participants
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="signicance-section ">
          <div className="container-fluid">
            <div className="row justify-content-end">
              <div className="col-lg-5">
                <img className="sign-img sign-right" src={Sign3} alt=""></img>
              </div>
            </div>
          </div>
          <div
            className={`significance-bg  ${isHovering3 ? "show" : ""}`}
            onMouseOver={handleMouseOver3}
            onMouseOut={handleMouseOut3}
          >
            <div className="container-fluid scroll-rotate-overflow">
              <div className=" our-clients-container2">
                <div className={`container py-5 container-significance  `}>
                  <div className="row justify-content-center one-deg align-items-center">
                    {isHovering3 ? (
                      <>
                        <div className="col-lg-3 my-auto">
                          <h2 className="significance-heading-stroke">
                            Networking Opportunities
                          </h2>
                        </div>
                        <div className=" col-lg-6 my-auto">
                          <p className="para-content-white">
                            With a large attendance of professionals and
                            stakeholders from across the digital landscape, the
                            summit provides unparalleled networking
                            opportunities. Attendees can connect with peers,
                            potential collaborators, clients, and mentors,
                            fostering valuable relationships that can lead to
                            business growth and professional development.
                          </p>
                        </div>
                      </>
                    ) : (
                      <div className="text-center col-lg-4 my-auto">
                        <h2 className="significance-heading">
                          Networking <br /> Opportunities
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* yello card */}
        <div className="signicance-section ">
          <div className="container-fluid">
            <div className="row justify-content-start">
              <div className="col-lg-5">
                <img className="sign-img left" src={Sign4} alt=""></img>
              </div>
            </div>
          </div>
          <div
            className={`significance-bg-black  ${isHovering4 ? "show" : ""}`}
            onMouseOver={handleMouseOver4}
            onMouseOut={handleMouseOut4}
          >
            <div className="container-fluid scroll-rotate-overflow">
              <div className="our-clients-container3">
                <div className={`container py-5 container-significance `}>
                  <div className="row justify-content-center three-deg align-items-center">
                    {isHovering4 ? (
                      <>
                        <div className=" col-lg-6 my-auto">
                          <p className="para-content-white text-dark">
                            The summit serves as a platform for showcasing the
                            latest innovations, trends, and technologies shaping
                            the digital industry. From cutting-edge tools and
                            platforms to emerging best practices, attendees gain
                            valuable insights into staying ahead of the curve
                            and driving innovation for their growth.
                          </p>
                        </div>
                        <div className=" col-lg-3 my-auto">
                          <h2 className="sign-head-stroke-black ">
                            Innovation and Trends
                          </h2>
                        </div>
                      </>
                    ) : (
                      <div className="text-center col-lg-4 my-auto">
                        <h2 className="significance-heading text-dark">
                          Innovation and
                          <br /> Trends
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="signicance-section mb-5">
          <div className="container-fluid">
            <div className="row justify-content-end">
              <div className="col-lg-5">
                <img className="sign-img sign-right" src={Sign5} alt=""></img>
              </div>
            </div>
          </div>
          <div
            className={`significance-bg   ${isHovering5 ? "show" : ""}`}
            onMouseOver={handleMouseOver5}
            onMouseOut={handleMouseOut5}
          >
            <div className="container-fluid scroll-rotate-overflow">
              <div className=" our-clients-container2">
                <div className={`container py-5 container-significance  `}>
                  <div className="row justify-content-center one-deg align-items-center">
                    {isHovering5 ? (
                      <>
                        <div className="col-lg-3 my-auto">
                          <h2 className="significance-heading-stroke">
                            Impact and Influence
                          </h2>
                        </div>
                        <div className=" col-lg-6 my-auto">
                          <p className="para-content-white">
                            Given its stature as the biggest event in the
                            digital space, the TN Digital Summit exerts a
                            significant influence on the direction and evolution
                            of the digital ecosystem. It serves as a catalyst
                            for change, driving conversations, collaborations,
                            and initiatives that propel the industry forward.
                          </p>
                        </div>
                      </>
                    ) : (
                      <div className="text-center col-lg-4 my-auto">
                        <h2 className="significance-heading">
                          Impact and <br />
                          Influence
                        </h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 digital-summit-bg">
        <div className="digital-summit-heading">
          <div className="container">
            <div className="stroke-text-container d-flex justify-content-center">
              <h1 className="bg-text-stroke grey-text-stroke">NUMBERS</h1>
              <h1 className="gradient-text-heading-yellow">
                TN DIGITAL SUMMIT IN NUMBERS
              </h1>
            </div>
          </div>
        </div>
        <div className="digital-summit-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="card-img-bg">
                  <img className="card-img" src={Card1} alt="" />
                  <div className="card-content">
                    <h1 className="card-content-number mb-0">11</h1>
                    <p className="para-content-white">Sessions</p>
                  </div>
                  <div className="card-overlay"></div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-img-bg">
                  <img className="card-img" src={Card2} alt="" />
                  <div className="card-content">
                    <h1 className="card-content-number mb-0">25+</h1>
                    <p className="para-content-white">Industry Experts</p>
                  </div>
                  <div className="card-overlay"></div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-img-bg">
                  <img className="card-img" src={Card3} alt="" />
                  <div className="card-content">
                    <h1 className="card-content-number mb-0">3 </h1>
                    <p className="para-content-white">Panel Discussions</p>
                  </div>
                  <div className="card-overlay"></div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-img-bg">
                  <img className="card-img" src={Card4} alt="" />
                  <div className="card-content">
                    <h1 className="card-content-number mb-0">35+ </h1>
                    <p className="para-content-white">Awardees</p>
                  </div>
                  <div className="card-overlay"></div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-img-bg">
                  <img className="card-img" src={Card5} alt="" />
                  <div className="card-content">
                    <h1 className="card-content-number mb-0">500+ </h1>
                    <p className="para-content-white">Attendees</p>
                  </div>
                  <div className="card-overlay"></div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-img-bg">
                  <img className="card-img" src={Card6} alt="" />
                  <div className="card-content">
                    <h1 className="card-content-number mb-0"> &#8734;</h1>
                    <p className="para-content-white">Value</p>
                  </div>
                  <div className="card-overlay"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5">
        <BookTickets />
      </div>
      <div>
        <SpeakersContent />
      </div>
      <div>
        <div className="">
          <div className="container my-5">
            <div className="stroke-text-container d-flex justify-content-center">
              <h1 className="bg-text-stroke">attend</h1>
              <h1 className="gradient-text-heading">why attend?</h1>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <h5 className="sub-heading-attend">
                  Absolutely, attending the TN Digital Summit offers a multitude
                  of compelling reasons
                </h5>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="why-attend-section">
                  <div className="row justify-content-center">
                    <div className="col-lg-4 pos-ab-why my-auto">
                      <h1 className="ahy-attent-heading">
                        Engage with <br /> the active <br /> Community
                      </h1>
                      <h1 className="ahy-attent-heading-2">
                        Engage with <br /> the active <br /> Community
                      </h1>
                    </div>
                    <div className="col-lg-8">
                      <div className="why-attend-card-container">
                        <div className="why-attend-card-1">
                          <img
                            className="why-attend-img-1"
                            src={WhyAttendCard1}
                            alt=""
                          ></img>
                          <div class="overlay-why-attend ">
                            <p class="text-p">
                              Interacting with a vibrant community of digital
                              professionals, entrepreneurs, and enthusiasts
                              fosters networking opportunities, collaboration,
                              and knowledge exchange. Engaging with like-minded
                              individuals can provide fresh perspectives,
                              insights, and potential partnerships.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* left to write */}
                <div className="why-attend-section">
                  <div className="row reverse-content justify-content-center">
                    <div className="col-lg-8">
                      <div className="why-attend-card-container">
                        <div className="why-attend-card-1">
                          <img
                            className="why-attend-img-2"
                            src={WhyAttendCard2}
                            alt=""
                          ></img>
                          <div class="overlay-why-attend-2 ">
                            <p class="text-p-2">
                              The summit offers a rich array of sessions,
                              workshops, and discussions delivered by industry
                              experts and thought leaders. Attendees can expect
                              to gain valuable knowledge, actionable strategies,
                              and practical insights that can significantly
                              benefit their businesses or career.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 pos-ab-why my-auto">
                      <h1 className="ahy-attent-heading-222">
                        Value, <br /> Value, <br /> more Value
                      </h1>
                      <h1 className="ahy-attent-heading-22">
                        Value, <br /> Value, <br /> more Value
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="why-attend-section">
                  <div className="row  justify-content-center">
                    <div className="col-lg-4 pos-ab-why my-auto">
                      <h1 className="ahy-attent-heading">
                        Get the <br />
                        long-waited <br /> Spark
                      </h1>
                      <h1 className="ahy-attent-heading-2">
                        Get the <br />
                        long-waited <br /> Spark
                      </h1>
                    </div>
                    <div className="col-lg-8">
                      <div className="why-attend-card-container">
                        <div className="why-attend-card-1">
                          <img
                            className="why-attend-img-1"
                            src={WhyAttendCard2}
                            alt=""
                          ></img>
                          <div class="overlay-why-attend ">
                            <p class="text-p">
                              Immersing yourself in a dynamic environment filled
                              with innovative ideas, frameworks and inspiring
                              individuals can reignite your passion for digital
                              innovation and digital entrepreneurship. The
                              summit serves as a catalyst for creativity,
                              motivation, and renewed energy to drive positive
                              change in your endeavours.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* left to write */}
                <div className="why-attend-section">
                  <div className="row reverse-content justify-content-center">
                    <div className="col-lg-8">
                      <div className="why-attend-card-container">
                        <div className="why-attend-card-1">
                          <img
                            className="why-attend-img-2"
                            src={WhyAttendCard4}
                            alt=""
                          ></img>
                          <div class="overlay-why-attend-2 ">
                            <p class="text-p-2">
                              Sharing a common passion for digital innovation
                              and growth creates a sense of camaraderie and
                              understanding among attendees. Connecting with
                              peers who share similar aspirations, challenges,
                              and experiences can provide invaluable support,
                              encouragement, and collaboration opportunities.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 pos-ab-why my-auto">
                      <h1 className="ahy-attent-heading-222">
                        Connect with <br /> people of the <br /> same disorder
                      </h1>
                      <h1 className="ahy-attent-heading-22">
                        Connect with <br /> people of the <br /> same disorder
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="why-attend-section">
                  <div className="row justify-content-center">
                    <div className="col-lg-4 pos-ab-why my-auto">
                      <h1 className="ahy-attent-heading">
                        Become a <br /> Better
                        <br /> Digital Marketer
                      </h1>
                      <h1 className="ahy-attent-heading-2">
                        Become a <br /> Better
                        <br /> Digital Marketer
                      </h1>
                    </div>
                    <div className="col-lg-8">
                      <div className="why-attend-card-container">
                        <div className="why-attend-card-1">
                          <img
                            className="why-attend-img-1"
                            src={WhyAttendCard3}
                            alt=""
                          ></img>
                          <div class="overlay-why-attend ">
                            <p class="text-p">
                              Whether you're a seasoned marketer or aspiring to
                              enhance your skills, the summit offers a wealth of
                              resources to elevate your marketing expertise.
                              Learn from industry experts, stay updated on the
                              latest trends and technologies, and discover
                              innovative strategies to enhance your marketing
                              campaigns and achieve better results.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* left to write */}
                <div className="why-attend-section">
                  <div className="row reverse-content justify-content-center">
                    <div className="col-lg-8">
                      <div className="why-attend-card-container">
                        <div className="why-attend-card-1">
                          <img
                            className="why-attend-img-2"
                            src={WhyAttendCard6}
                            alt=""
                          ></img>
                          <div class="overlay-why-attend-2 ">
                            <p class="text-p-2">
                              For content creators, the summit provides
                              insights, strategies, and best practices to craft
                              compelling content that resonates with your target
                              audience. Gain inspiration, learn about emerging
                              content formats and platforms, and explore
                              techniques to captivate and engage your audience
                              effectively.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 pos-ab-why my-auto">
                      <h1 className="ahy-attent-heading-222">
                        Create Content <br /> that hooks <br /> the Audience
                      </h1>
                      <h1 className="ahy-attent-heading-22">
                        Create Content <br /> that hooks <br /> the Audience
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilton-section">
            <div className="container">
              <div className="row reverse-content justify-content-center">
                <div className="col-lg-5">
                  <img className="hilton-img" src={HiltonImg} alt="" />
                </div>
                <div className="col-lg-5">
                  <div className="tnds-bg-img ">
                    <img className="tnds-img" src={TNDSBGBottom} alt=""></img>
                    <div className="tnds-text-right py-3">
                      <h1 className="mb-0">
                        <span className="hilton-stroke">TN</span>{" "}
                        <span className="hilton-black">Digital</span>
                      </h1>
                      <h1 className="mb-0">
                        <span className="hilton-black">Summit</span>
                      </h1>
                      <h1 className="mb-0">
                        <span className="hilton-stroke fs-1">25 - 26 May</span>{" "}
                        <span className="hilton-black fs-1">2024</span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5">
            <Hilton />
          </div>
        </div>
      </div>
      <div>
        <div className="mt-5">
          <div className="container ">
            <div className="stroke-text-container d-flex justify-content-center">
              <h1 className="bg-text-stroke">attend</h1>
              <h1 className="gradient-text-heading">Who can attend?</h1>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="wca-card-bg-yellow">
                      <div className="wca-card-bg-color-black">
                        <div className="row">
                          <div className="col-lg-7">
                            <h2 className="attend-card-text-white">
                              Business Owners who want to scale using Digital
                            </h2>
                          </div>
                        </div>
                        <img className="wca-img-anim" src={WCA1} alt=""></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wca-card-bg-black">
                      <div className="wca-card-bg-color-yellow">
                        <div className="row">
                          <div className="col-lg-7">
                            <h2 className="attend-card-text-black">
                              Coaches and Consultants
                            </h2>
                          </div>
                        </div>
                        <img className="wca-img-anim" src={WCA2} alt=""></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wca-card-bg-black">
                      <div className="wca-card-bg-color-yellow">
                        <div className="row">
                          <div className="col-lg-7">
                            <h2 className="attend-card-text-black">
                              Digital Marketers and Sales Professionals
                            </h2>
                          </div>
                        </div>
                        <img className="wca-img-anim" src={WCA3} alt=""></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wca-card-bg-yellow">
                      <div className="wca-card-bg-color-black">
                        <div className="row">
                          <div className="col-lg-7 my-auto">
                            <h2 className="attend-card-text-white">
                              Digital Agency Owners
                            </h2>
                          </div>
                        </div>
                        <img className="wca-img-anim" src={WCA4} alt=""></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wca-card-bg-yellow">
                      <div className="wca-card-bg-color-black">
                        <div className="row">
                          <div className="col-lg-7 my-auto">
                            <h2 className="attend-card-text-white">
                              Digital Aspirants and Enthusiasts
                            </h2>
                          </div>
                        </div>
                        <img className="wca-img-anim" src={WCA5} alt=""></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wca-card-bg-black">
                      <div className="wca-card-bg-color-yellow">
                        <div className="row">
                          <div className="col-lg-7">
                            <h2 className="attend-card-text-black">
                              Content Creators
                            </h2>
                          </div>
                        </div>
                        <img className="wca-img-anim" src={WCA6} alt=""></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 mt-5 element-bg">
        <div className="digital-summit-heading mb-5">
          <div className="container">
            <div className="stroke-text-container d-flex justify-content-center">
              <h1 className="bg-text-stroke grey-text-stroke">elements</h1>
              <h1 className="gradient-text-heading-yellow text-white">
                event elements
              </h1>
            </div>
          </div>
        </div>
        <div className="digital-summit-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="card-img-bg-element">
                  <img className="card-img-element" src={E1} alt="" />
                  <div className="card-content-element">
                    <h1 className="card-content-heading-element mb-0">
                      Keynote Presentations
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-img-bg-element">
                  <img className="card-img-element" src={E2} alt="" />
                  <div className="card-content-element">
                    <h1 className="card-content-heading-element mb-0">
                      Panel Discussions
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-img-bg-element">
                  <img className="card-img-element" src={E3} alt="" />
                  <div className="card-content-element">
                    <h1 className="card-content-heading-element mb-0">
                      Networking{" "}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-img-bg-element">
                  <img className="card-img-element" src={E4} alt="" />
                  <div className="card-content-element">
                    <h1 className="card-content-heading-element mb-0">
                      Q & A Sessions
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-img-bg-element">
                  <img className="card-img-element" src={E5} alt="" />
                  <div className="card-content-element">
                    <h1 className="card-content-heading-element mb-0">
                      Awards Night
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-img-bg-element">
                  <img className="card-img-element" src={E6} alt="" />
                  <div className="card-content-element">
                    <h1 className="card-content-heading-element mb-0">
                      Business Opportunities
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="innovate-section">
        <div className="innovate-bg">
          <div className="container py-5">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="inspire-container">
                      <h5 className="inspire-content">INSPIRE</h5>
                      <h2 className="banner-white-text mb-0">INNOVATE</h2>
                      <h1 className="influence-heading">Influence</h1>
                      <p className="banner-para-content mb-4">
                        Unlocking Tomorrow's Digital Landscape
                      </p>
                      <button
                        // onClick={() => setBuyTicketModal(true)}
                        onClick={() => navigation('/BuyTicket')}
                        class="btn yellow-btn"
                        data="Buy Ticket"
                      >
                        <div class="figure">
                          <img
                            className="ticket-gold"
                            src={TicketBlack}
                            alt=""
                          />
                          <img
                            className="ticket-white"
                            src={TicketGold}
                            alt=""
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="influece-img" src={InflunceImg} alt="" />
      </div>
    </div>
  );
};

export default HomeContent;
